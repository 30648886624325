import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { NavLink } from "./NavLink";
import { Bars3Icon, DevicePhoneMobileIcon, XMarkIcon } from "@heroicons/react/24/solid";
import $ from "jquery";
import styles from "./styles.module.css";
import { menuLinks } from "../../Data/menuLinks";
import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { selectUser } from "../../store/slices/userSlice";
import { Headset } from "@phosphor-icons/react";
interface SidebarProps {
  links: any;
  sidebarState: any;
}

export function Sidebar({ links, sidebarState }: SidebarProps) {
  const { collapse, setCollapse, mobileCollapse, isMobile, handleCollapse, isNotebook } = sidebarState;
  const [activeLink, setActiveLink] = useState(getFirstUrlListId);
  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { user } = useSelector(selectUser);
  const roles = user[0]?.roles;

  function handleNavLinkClick(listId) {
    setActiveLink(listId);
  }

  function getFirstUrlListId() {
    let firstUrlLinkId = 0;
    const firstUrlOnLoad = window.location.pathname;
    const urlSeparatedByDashboard = firstUrlOnLoad.split("/dashboard/");
    const linkAfterDashboard = urlSeparatedByDashboard[1];

    menuLinks.forEach((menuLink) => {
      if (menuLink.children[0].href === linkAfterDashboard) {
        /*         if (linkAfterDashboard === "lista-pacientes") {
                  firstUrlLinkId = 0;
                }
                else if (linkAfterDashboard === "indicadores-de-oportunidade") {
                  firstUrlLinkId = 1;
                } else {
                  firstUrlLinkId = menuLink.id - 3;
                } */
        firstUrlLinkId = menuLink.id;
      }
    });
    return firstUrlLinkId;
  }
  useEffect(() => {
    if (isMobile) {
      handleCollapse();
    }

  }, []);

  useEffect(() => {
    if (isCefalometria) {
      setCollapse(true);
    } else {
      setCollapse(true);

    }
  }, [isMobile]);

  useEffect(() => {
    if (isNotebook) { setCollapse(true); }
  }, [isNotebook]);

  const determineCollapseStatus = () => {
    if (isMobile && mobileCollapse) {
      return `${styles.aside_collapse}`;
    } else if (isMobile && !mobileCollapse) {
      return `${styles.aside}`;
    } else if (!isMobile && collapse) {
      return `${styles.aside_collapse}`;
    } else if (!isMobile && !collapse) {
      return `${styles.aside}`;
    }
  };

  const mobileClassName = determineCollapseStatus();

  useEffect(() => {
    let deferredPrompt;
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;
      $("#installModal").show();
    });

    $("#installButton").on("click", () => {
      if (deferredPrompt) {
        console.log("O usuário aceitou a instalação");
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("O usuário aceitou a instalação");
          } else {
            console.log("O usuário recusou a instalação");
          }
          deferredPrompt = null;
          $("#installModal").hide();
        });
      }
    });

  }, []);


  return (
    <aside className={mobileClassName}>
      {collapse ? (
        <>
          <div className={styles.containerHeaderCollapse}>
            <div className={styles.buttonHamburguer} onClick={handleCollapse}>
              {
                isMobile ? <XMarkIcon /> : <Bars3Icon />
              }
            </div>

            <Link to="/dashboard/lista-pacientes">
              {isMobile ? <img src="/logo_serodonto.svg" width={180} height={60} /> : <img src="/logo.svg" />}
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className={styles.containerHeader}>
            <Link to="/dashboard/lista-pacientes">
              <div>
                <img src="/logo_serodonto.svg" width={"180px"} />
              </div>
            </Link>
            <div className={styles.buttonHamburguer} onClick={handleCollapse}>
              <Bars3Icon />
            </div>
          </div>
        </>
      )}

      <nav>
        <ul>
          {links === undefined
            ? menuLinks.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  item={item}
                  collapse={collapse}
                  listId={index}
                  isActive={index === activeLink}
                  onNavLinkClick={handleNavLinkClick}
                  handleCollapse={handleCollapse}
                />
              );
            })
            : links.map((item: any, index: any) => {
              return (
                <NavLink
                  key={index}
                  item={item}
                  collapse={collapse}
                  listId={index}
                  isActive={index === activeLink}
                  onNavLinkClick={handleNavLinkClick}
                  handleCollapse={handleCollapse}
                />
              );
            })}


          {
            roles === "NAOAUTENTICADO" ?
              <li
                className={styles.li} style={{ marginTop: '16px', paddingLeft: '5px' }}
                title='Instalação do aplicativo'
                onClick={handleCollapse}
              >
                <Link
                  className={styles.container_createYourAccounts} to="/dashboard/instalar-aplicativo"
                  style={{
                    marginLeft: collapse ? '21.5px' : '15px'
                  }}
                >
                  <DevicePhoneMobileIcon width={23} />
                  <div >
                    {isMobile && "Instalar Aplicativo"}
                    {!collapse && " Instalar Aplicativo"}

                  </div>
                </Link>
              </li>
              :
              <li
                className={styles.li} style={{ marginTop: '16px', paddingLeft: '5px' }}
                title='Instalação do aplicativo'
                onClick={handleCollapse}
              >
                <div
                  className={styles.container_createYourAccounts}
                  style={{
                    marginLeft: collapse ? '21.5px' : '15px'
                  }}
                  id="installModal"
                >
                  <DevicePhoneMobileIcon width={23} />
                  <div id="installButton" >
                    {isMobile && "Instalar Aplicativo"}
                    {!collapse && " Instalar Aplicativo"}
                  </div>
                </div>
              </li>
          }
        </ul>
      </nav>

      {
        collapse && isMobile && (
          <footer className={styles.footerCollapse}>
            <p>
              <Headset size={20} />
              Precisa de ajuda? Fale conosco
            </p>
            <div className={styles.containerLogos}>
              <img src="/logo_cdt.svg" alt="logo" />
              <img src="/logo_serodonto.svg" />
            </div>
          </footer>
        )
      }
    </aside>
  );
}
