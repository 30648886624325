import { useEffect, useState } from 'react';
import {
    ArrowsUpDownIcon
} from "@heroicons/react/24/outline";

import { useOpcoes } from '../../../NewSideberCefx/OpcoesContext';

import styles from './styles.module.css';

interface OpcoesProps {
    imageParams?: string;
    getImagenRotation: (rotate: number) => void;
}

export function OpcoesView({ imageParams, getImagenRotation }: OpcoesProps) {
    const [imageRotation, setImageRotation] = useState(0);

    const { brightness, handleBrightnessChange, contrast, handleContrastChange } = useOpcoes();

    const handleRotateHorizontal = () => {
        setImageRotation((prevRotation) => (prevRotation === 90 ? -90 : 90));
    };

    const handleRotateVertical = () => {
        setImageRotation((prevRotation) => (prevRotation === 0 ? 180 : 0));
    };

    useEffect(() => {
        getImagenRotation(imageRotation);
    }, [imageRotation]);

    return (
        <div className={styles.containerOption}>
            <div>
                <p className={styles.title}> Opções Básicas</p>

                <div>
                    <div className={styles.containerInputOpcoesBasicas}>
                        <span>
                            Brilho
                            <span>{brightness}</span>
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={200}
                            value={brightness}
                            onChange={handleBrightnessChange}
                        />
                    </div>

                    <div className={styles.containerInputOpcoesBasicas}>
                        <span>
                            Contraste
                            <span>{contrast}</span>
                        </span>
                        <input
                            type="range"
                            min={0}
                            max={100}
                            value={contrast}
                            onChange={handleContrastChange}
                        />
                    </div>

                    <div
                        className={styles.containerButtons}
                        style={{ display: imageParams.includes("dfx") ? 'none' : 'flex' }}
                    >
                        <div className={styles.containerButton}>
                            <button
                                type="button"
                                onClick={handleRotateHorizontal}
                            >
                                <ArrowsUpDownIcon width={16} />
                                Horizontal
                            </button>
                        </div>

                        <div className={styles.containerButton}>
                            <button
                                type="button"
                                onClick={handleRotateVertical}
                            >
                                <ArrowsUpDownIcon width={16} />
                                Vertical
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}