import { Header } from "./Header";
import { Navigation } from "./Navigation";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import axios from "axios";

import styles from "./styles.module.css";
import { selectPatientsList } from "../../store/slices/patientListSlice";
import { useContext, useEffect, useState } from "react";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import {
  selectResearchPatient,
  setResearchPatient,
} from "../../store/slices/researchPatientSlice";
import { setHeaderLogo } from "../../store/slices/headerLogoSlice";
import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { selectPatientsCefxAConcluir, setpatientCefxAConcluir } from "../../store/slices/patientCefxAConcluirSlice";
import { selectPatientCefxConcluidos, setpatientCefxConcluidos } from "../../store/slices/patientCefxConcluidosSlice";
import { selectPatientsCefxEmAndamento, setpatientCefxEmAndamento } from "../../store/slices/patientCefxEmAndamentoSlice";
import { PatientContext } from "../PatientList/PatientProvider";
import { addPatientWithImagens, selectPatientsWithImages } from "../../store/slices/patientsWithImagesSlice";
import BuggyComponent from "../../components/BuggyComponente/BuggyComponent";

export function UserProfile(objShare?) {
  const dispatch = useDispatch();

  const API_URL = process.env.REACT_APP_API_URL;
  const toConcludeStorageAtualizado = localStorage.getItem('toConclude');

  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { patientsCefxAConcluir } = useSelector(selectPatientsCefxAConcluir);
  const { patientsCefxConcluidos } = useSelector(selectPatientCefxConcluidos);
  const { patientsCefxEmAndamento } = useSelector(
    selectPatientsCefxEmAndamento
  );

  const { patientsWithImages } = useSelector(selectPatientsWithImages);
  const { patients } = useSelector(selectPatientsList);
  const { researchPatient } = useSelector(selectResearchPatient);
  const { user } = useSelector(selectUser);
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const query = queryString.parse(search) as {
    paciente: string;
    seqAtend: string;
    idClient: string;
    idPacient: string;
  };

  const {
    filteredPatientList,
  } = useContext(PatientContext);

  const pacienteQuery = query.paciente?.replace(/_/g, " ");
  const pacienteQuerySeqAtend = Number(query.seqAtend);

  const excludedFormats = ['rel', 'xml', 'dat', 'cef', 'json', 'doc', 'new', 'plan', 'tomo_1', 'tomo', 'prop', 'txt', 'bmp'];

  const getPacient = () => {
    const searchPacient = (list) => {
      return list.find((paciente) =>
        paciente.idPaciente == query.idPacient &&
        paciente.seqAtend == query.seqAtend &&
        (paciente.idClient == query.idClient || paciente.idClient == "" || query.idClient == "")
      );
    };

    if (isCefalometria) {
      let result = searchPacient(patientsCefxAConcluir);
      if (!result) result = searchPacient(patientsCefxConcluidos);
      if (!result) result = searchPacient(patientsCefxEmAndamento);
      return result;
    } else {
      if (patients.length > 0) {
        let result = searchPacient(patients);
        if (result) return result;
      }
      if (filteredPatientList.length > 0) {
        return searchPacient(filteredPatientList);
      }
    }

    return undefined;
  };;

  const pacient = getPacient();

  const [dataPacient, setDataPacient] = useState(pacient);
  const [loading, setLoading] = useState(false);
  const [loadingImagens, setLoadingImagens] = useState(false);

  localStorage.setItem("idPaciente", id);
  localStorage.setItem("atd", dataPacient?.seqAtend);

  const { idClient: urlIdClient } = query;

  useEffect(() => {
    setLoading(true);

    if (objShare.objShare) {
      dispatch(setResearchPatient(objShare.objShare));
      setDataPacient(objShare.objShare);
      setLoading(false);
    } else {
      const getPatientByName = async () => {
        if (pacient) {
          dispatch(setResearchPatient(false));
          setLoading(false);
          return;
        } else if (!dataPacient && user && user.length > 0) {
          const { idClient: urlIdClient } = query;
          const filteredUser = user.find((u) => u.idClient === urlIdClient);
          const { hash, idClient } = filteredUser;
          const page = 0;
          const search = idClient !== '194' ? pacienteQuery : pacienteQuery.split(' ')[0];

          const trimmedValues = {
            idClient,
            hash,
            page,
          };

          try {
            if (!isCefalometria) {
              const result = await Api.GetPatientList(trimmedValues, search);

              if (result.isSuccess === true) {

                const foundPatient = result.data.patients.find(
                  (patient) => patient.seqAtend === pacienteQuerySeqAtend
                );

                if (foundPatient.imagem.includes('https://app.evolcloud.com.br')) {
                  const hashPacienteEvol = user.find((pacient) => pacient.idClient == dataPacient?.idClient);
                  const resultImages = await Api.GetImagensPatientEvolcloud(hashPacienteEvol.hash, idClient, foundPatient.seqAtend, foundPatient.uid)

                  if (resultImages.isSuccess === true) {
                    const pacienteAtualizado = {
                      ...foundPatient,
                      imagem: `https://cdt2so.blob.core.windows.net/3889/061922/93d00bdb-823c-4e3a-a0c0-8dcb3894fedf/dcm/00/inicio.png`,
                      imagensPaciente: resultImages.data.filter(imagem => {
                        const link = imagem.link;
                        const includeLink = link.includes('/img') || link.endsWith('.dfx') || link.endsWith('.zip') || link.includes('.pdf');
                        const excludeLink = excludedFormats.some(format => link.includes(`${format}`) && !link.endsWith('.pdf'));
                        return includeLink && !excludeLink;
                      }).map(imagem => ({
                        ...imagem,
                      }))
                    };

                    // dispatch(setResearchPatient(pacienteAtualizado));

                    const pacientAlreadyHasImages = patientsWithImages.some(paciente => paciente.ss === pacienteAtualizado.ss || paciente.id === pacienteAtualizado.id);

                    if (!pacientAlreadyHasImages) {
                      dispatch(addPatientWithImagens(pacienteAtualizado));
                    }

                    setDataPacient(pacienteAtualizado);
                  }
                } else {
                  const resultImages = await Api.GetImagensPatient(idClient, foundPatient.idPaciente, foundPatient.seqAtend);
                  if (resultImages.isSuccess === true) {
                    const pacienteAtualizado = {
                      ...foundPatient,
                      imagem: `${API_URL}${foundPatient.imagem}`,
                      imagensPaciente: resultImages.data
                        .filter(imagem => {
                          if (imagem.link.startsWith('https')) {
                            return (imagem.link.includes('/img') || imagem.nome.endsWith('.dfx') || imagem.nome.endsWith('.zip')) &&
                              !imagem.nome.includes('tomo_m');
                          }
                          return !imagem.nome.includes('tomo_m');
                        })
                        .map(imagem => ({
                          ...imagem,
                          link: imagem.link.startsWith('https') ? imagem.link : `${API_URL}${imagem.link}`,
                          icone: imagem.icone.startsWith('https') ? imagem.icone : `${API_URL}${imagem.icone}`
                        }))
                    };

                    const pacientAlreadyHasImages = patientsWithImages.some(paciente => paciente.ss === pacienteAtualizado.ss || paciente.id === pacienteAtualizado.id);

                    if (!pacientAlreadyHasImages) {
                      dispatch(addPatientWithImagens(pacienteAtualizado));
                    }

                    //  dispatch(setResearchPatient(pacienteAtualizado));

                    setDataPacient(pacienteAtualizado);
                  }
                }

              }
            } else {
              const resultCefalometria = await Api.GetPatientListCefx(
                { idClient, hash, page },
                { process_id: -2, to_conclude: toConcludeStorageAtualizado },
                { searchName: query.seqAtend || search }
              );

              if (resultCefalometria.isSuccess === true) {
                const foundPatient = resultCefalometria.data.patients.find(
                  (patient) => patient.seqAtend === pacienteQuerySeqAtend
                );

                const resultImages = await Api.GetImagensPatient(idClient, foundPatient.idPaciente, foundPatient.seqAtend);
                if (resultImages.isSuccess === true) {
                  const pacienteAtualizado = {
                    ...foundPatient,
                    imagem: `${API_URL}${foundPatient.imagem}`,
                    imagensPaciente: resultImages.data
                      .filter(imagem => {
                        if (imagem.link.startsWith('https')) {
                          return (imagem.link.includes('/img') || imagem.nome.endsWith('.dfx') || imagem.nome.endsWith('.zip')) &&
                            !imagem.nome.includes('tomo_m');
                        }
                        return !imagem.nome.includes('tomo_m');
                      })
                      .map(imagem => ({
                        ...imagem,
                        link: imagem.link.startsWith('https') ? imagem.link : `${API_URL}${imagem.link}`,
                        icone: imagem.icone.startsWith('https') ? imagem.icone : `${API_URL}${imagem.icone}`
                      }))
                  };

                  const pacientAlreadyHasImages = patientsWithImages.some(paciente => paciente.ss === pacienteAtualizado.ss || paciente.id === pacienteAtualizado.id);

                  if (!pacientAlreadyHasImages) {
                    dispatch(addPatientWithImagens(pacienteAtualizado));
                  }
                  //  dispatch(setResearchPatient(pacienteAtualizado));

                  setDataPacient(pacienteAtualizado);
                }
              }
            }
            setLoading(false);
          } catch (error) {
            if (axios.isAxiosError(error)) {
              console.log(error);
            } else {
              console.log(error);
            }
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      };
      getPatientByName();
    }

  }, [dataPacient, objShare]);

  useEffect(() => {
    setLoadingImagens(true);
    const pacientAlreadyHasImages = patientsWithImages.find(paciente => paciente.idClient && query.idClient && paciente.idPaciente == query.idPacient && paciente.seqAtend == query.seqAtend)

    if (pacientAlreadyHasImages) {
      setDataPacient(pacientAlreadyHasImages);
      setLoadingImagens(false);
      return;
    }

    const fetchDataImagensPacient = async () => {
      try {
        if (dataPacient.imagem.includes('https://cdt2so.blob.core.windows.net')) {

          const hashPacienteEvol = user.find((pacient) => pacient.idClient == dataPacient?.idClient);
          const result = await Api.GetImagensPatientEvolcloud(hashPacienteEvol.hash, dataPacient.idClient, dataPacient.seqAtend, dataPacient.uid);
          const imagensFiltradas = result.data.filter(imagem => !imagem.nome.includes('m.jpg'));

          if (result.isSuccess === true) {
            const pacienteAtualizado = {
              ...dataPacient,
              imagem: `https://cdt2so.blob.core.windows.net/3889/061922/93d00bdb-823c-4e3a-a0c0-8dcb3894fedf/dcm/00/inicio.png`,
              imagensPaciente: result.data.filter(imagem => {
                const link = imagem.link;
                const includeLink = link.includes('/img') || link.endsWith('.dfx') || link.endsWith('.zip');
                const excludeLink = excludedFormats.some(format => link.includes(`${format}`)) && !link.endsWith('.pdf');
                return includeLink && !excludeLink;
              }).map(imagem => ({
                ...imagem,
              }))
            };

            setDataPacient(pacienteAtualizado);

            const pacientAlreadyHasImages = patientsWithImages.some(paciente => paciente.ss === pacienteAtualizado.ss);

            if (!pacientAlreadyHasImages) {
              dispatch(addPatientWithImagens(pacienteAtualizado));
            } else {
              return
            }

          }
        } else {

          const result = await Api.GetImagensPatient(dataPacient.idClient, dataPacient.idPaciente, dataPacient.seqAtend);

          if (result.isSuccess === true) {
            const pacienteAtualizado = {
              ...dataPacient,
              imagensPaciente: result.data
                .filter(imagem => {
                  if (imagem.link.startsWith('https')) {
                    return (imagem.link.includes('/img') || imagem.nome.endsWith('.dfx') || imagem.nome.endsWith('.zip')) &&
                      !imagem.nome.includes('tomo_m');
                  }
                  return !imagem.nome.includes('tomo_m');
                })
                .map(imagem => ({
                  ...imagem,
                  link: imagem.link.startsWith('https') ? imagem.link : `${API_URL}${imagem.link}`,
                  icone: imagem.icone.startsWith('https') ? imagem.icone : `${API_URL}${imagem.icone}`
                }))
            };

            setDataPacient(pacienteAtualizado);

            const pacientAlreadyHasImages = patientsWithImages.some(paciente => paciente.ss === pacienteAtualizado.ss);

            if (!pacientAlreadyHasImages) {
              dispatch(addPatientWithImagens(pacienteAtualizado));
            } else {
              return
            }

            const updatedPatients = patients.map(patient => {
              if (patient.id === pacienteAtualizado.id && patient.idClient === pacienteAtualizado.idClient && patient.seqAtend === pacienteAtualizado.seqAtend) {
                return pacienteAtualizado;
              }
              return patient;
            });

            if (isCefalometria) {
              if (toConcludeStorageAtualizado == '0') {
                dispatch(setpatientCefxAConcluir(updatedPatients));
              } else if (toConcludeStorageAtualizado == '1') {
                dispatch(setpatientCefxConcluidos(updatedPatients));
              } else if (toConcludeStorageAtualizado == '2') {
                setpatientCefxEmAndamento(updatedPatients);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingImagens(false);
      }
    };

    fetchDataImagensPacient();
  }, []);

  useEffect(() => {
    if (dataPacient) {
      dispatch(
        setHeaderLogo(
          `https://cdt.com.br/cli/p/getImagem.aspx?cmp=${dataPacient.idClient}&nm=2`
        )
      );
    }
  }, [dataPacient]);

  return (
    <div className={styles.wrapper}>
      <>
        {loading ? (
          <div className={styles.loading_container}>
            <div className={styles.loading_spinne}></div>
            <p>Por favor, Aguarde...</p>
          </div>
        ) : !dataPacient && !loading && !loadingImagens ? (
          <div className={styles.containerNopacient}>Desculpe, não conseguimos encontrar o paciente.</div>
        ) : (
          <>
            <Header infoPacient={dataPacient} />
            {
              loadingImagens ?
                <div className={styles.loading_containerImagens}>
                  <div className={styles.loading_spinneImagens}></div>
                  <p>Carregando...</p>
                </div>
                :
                <Navigation
                  infoPacient={dataPacient}
                  isShare={objShare.objShare ? true : false}
                />
            }
          </>
        )}
      </>
    </div>
  );
}
