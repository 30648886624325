import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    toConcludeCefx: 0
}

export const ToconcludeCefxSlice= createSlice({
    name: 'toConcludeCefx',
    initialState,
    reducers: {
        setToConcludeCefx: (state, action) => {
            state.toConcludeCefx = action.payload
        }
    }
})

export const { setToConcludeCefx } = ToconcludeCefxSlice.actions;

export const selectToConcludeCefx = (state: any) => state.toConcludeCefx;

export default ToconcludeCefxSlice.reducer;
