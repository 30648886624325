import { createContext, useState, useEffect, useRef } from "react";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPatientsList,
  setpatients
} from "../../store/slices/patientListSlice";
import { selectisClinicConnectionsComplete } from "../../store/slices/isClinicConnectionsCompleteSlice";
import { setIsClinicConnectionsComplete } from "../../store/slices/isClinicConnectionsCompleteSlice";

import { addPatientCefxAConcluir } from "../../store/slices/patientCefxAConcluirSlice";
import { addPatientCefxConcluidos } from "../../store/slices/patientCefxConcluidosSlice";
import { addPatientCefxEmAndamento } from "../../store/slices/patientCefxEmAndamentoSlice";

import { selectPatientsCefxAConcluir } from "../../store/slices/patientCefxAConcluirSlice";
import { selectPatientCefxConcluidos } from "../../store/slices/patientCefxConcluidosSlice";
import { selectPatientsCefxEmAndamento } from "../../store/slices/patientCefxEmAndamentoSlice";
import { useReloadPatientsList } from "../../hooks/useReloadPatientsLists";

import {
  addTempPatients, selectTempPatientsList,
  setTempPatients
} from "../../store/slices/tempPatientListSlice";
import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { selectProcesso } from "../../store/slices/processoSlice";
import { selectToConcludeCefx } from "../../store/slices/toConcludeCefxSlice";
import { markConcludeLoaded } from "../../store/slices/loadedConcludesSlices";

export const PatientContext = createContext(null);

export const PatientProvider = ({ children }) => {
  const dispatch = useDispatch();

  const API_URL = process.env.REACT_APP_API_URL;

  const handleReloadList = useReloadPatientsList();

  const { patientsCefxAConcluir, lastPageLoaded, totalPages } = useSelector(selectPatientsCefxAConcluir);
  const { patientsCefxConcluidos } = useSelector(selectPatientCefxConcluidos);
  const { patientsCefxEmAndamento } = useSelector(
    selectPatientsCefxEmAndamento
  );

  const { user } = useSelector(selectUser);
  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { toConcludeCefx } = useSelector(selectToConcludeCefx);
  const { processo } = useSelector(selectProcesso);
  const { patients } = useSelector(selectPatientsList);
  const { tempPatients } = useSelector(selectTempPatientsList);
  const { isClinicConnectionsComplete
  } = useSelector(
    selectisClinicConnectionsComplete
  );

  const [patientList, setPatientList] = useState([]);
  const [filteredPatientList, setFilteredPatientList] = useState([]);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [lastPage, setLastPage] = useState(false);
  const [shouldAbort, setShouldAbort] = useState(false);
  const [noPatientsCefx, setNoPatientsCefx] = useState(false);
  const [loadingButtonChecklist, setLoadingButtonCheckList] = useState(false);
  const [resultProcessos, setResultProcessos] = useState([]);
  const [updateCefxList, setUpdateCefxList] = useState(false);
  const [loading, setLoading] = useState(false);

  const abortControllerRef = useRef(null);

  const reorderedClinics = useRef([]);

  const resetPatientLoading = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    handleReloadList();

    setPatientList([]);
    setShouldAbort(true);
    setFilteredPatientList([]);
  };

  const reactivePatientsLoading = () => {
    if (user && user.length > 0) {
      resetPatientLoading();
    }
  };

  const fetchPatientsForClinic = async (userItem, currentPage, signal) => {
    if (!isCefalometria) {
      abortControllerRef.current = new AbortController();
      try {
        const response = await Api.GetPatientList(
          {
            idClient: userItem.idClient,
            hash: userItem.hash,
            page: currentPage,
          },
          "",
          signal
        );
        if (response.isSuccess) {
          response.data.patients = response.data.patients?.map((patient) => {
            return {
              ...patient,
              imagem: patient.imagem.includes('https://app.evolcloud.com.br') ? 'https://cdt2so.blob.core.windows.net/3889/061922/93d00bdb-823c-4e3a-a0c0-8dcb3894fedf/dcm/00/inicio.png' : `${API_URL}${patient.imagem}`,
              clinic: userItem.clinicalName,
              idClient: userItem.idClient,
            };
          });
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        return [];
      }
    } else {
      return;
    }
  };

  const fetchFirstPageForAllClinics = async (users, signal) => {
    if (!isCefalometria) {
      let fastestClinicIndex = -1;
      let fastestTime = Infinity;

      const startTimes = users.map(() => Date.now());

      const promises = users.map((userItem, i) =>
        fetchPatientsForClinic(userItem, 0, signal).then((response) => {
          const elapsedTime = Date.now() - startTimes[i];
          if (elapsedTime < fastestTime) {
            fastestTime = elapsedTime;
            fastestClinicIndex = i;
          }
          if (response.patients && !isCefalometria) {
            setPatientList((prevPatients) => [
              ...prevPatients,
              ...response.patients,
            ]);
          }
        })
      );

      await Promise.race(promises);

      return fastestClinicIndex;
    }
  };

  const fetchAllPatients = async (users, signal) => {
    setLoading(true);

    if (!isCefalometria) {
      const localClinicConnection = JSON.parse(
        localStorage.getItem("currentClinicConnection")
      );
      const localFatestClinic = JSON.parse(
        localStorage.getItem("fatestClinicIndex")
      );

      let fastestClinicIndex: number;

      fastestClinicIndex = await fetchFirstPageForAllClinics(users, signal);
      localStorage.setItem(
        "fatestClinicIndex",
        JSON.stringify(fastestClinicIndex)
      );


      let clinics = [...users];
      let fatestClinic = clinics.splice(fastestClinicIndex, 1)[0];
      clinics.unshift(fatestClinic);
      reorderedClinics.current = clinics;

      const currentPageForClinic = new Array(clinics.length).fill(0);
      if (
        localClinicConnection &&
        localClinicConnection.clinicIndex === fastestClinicIndex
      ) {
        currentPageForClinic[0] = localClinicConnection.currentPage;
      } else {
        currentPageForClinic[0] = 1;
      }

      for (
        let i = localClinicConnection?.clinicIndex || 0;
        i < clinics.length;
        i++
      ) {
        let clinicIndex = localClinicConnection?.clinicIndex || i;
        const userItem = clinics[clinicIndex];
        let currentPage;
        if (localClinicConnection) {
          currentPage = localClinicConnection.currentPage;
          currentPageForClinic[clinicIndex] = currentPage;
        } else {
          currentPage = currentPageForClinic[clinicIndex];
        }

        let totalPages;

        while (currentPage < totalPages || totalPages === undefined) {
          if (signal.aborted) {
            localStorage.setItem("currentClinicConnection", null);
            localStorage.setItem("fatestClinicIndex", null);
            i = clinics.length + 1;
            throw new Error("Fetch aborted");
          }
          const response = await fetchPatientsForClinic(
            userItem,
            currentPage,
            signal
          );
          let patients = response.patients;
          if (patients) {
            /* dispatch(addTempPatients([patients])); */
            dispatch(addTempPatients([patients]));

          }

          currentPage++;
          localStorage.setItem(
            "currentClinicConnection",
            JSON.stringify({ clinicIndex: clinicIndex, currentPage: currentPage })
          );
          currentPageForClinic[clinicIndex] = currentPage;
          if (totalPages === undefined || totalPages === null) {
            totalPages = response.totalPages;
          }
        }
        clinicIndex++;
        if (clinics.length === clinicIndex) {
          setLoading(false);
          sessionStorage.setItem("loadingState", JSON.stringify(false));

          dispatch(setIsClinicConnectionsComplete(true));

          return;
        }
      }
      dispatch(setIsClinicConnectionsComplete(true));

      sessionStorage.setItem("loadingState", JSON.stringify(false));
    }

  };

  const getFirstIndex = () => {
    const newList = [...tempPatients];
    const result = newList.splice(0, 1)[0];
    dispatch(setTempPatients(newList));

    return result ? result : [];
  };

  //Inicio Pacientes CEFX
  const toConcludeRef = useRef(Number(toConcludeCefx));
  const toProcessoRef = useRef(null);

  const cefxPatientIsLoaded = useSelector((state: any) => {
    const loaded = state.loadedConcludes[toConcludeCefx] ?? false;

    let dataExists = false;

    switch (toConcludeCefx) {
      case 0:
        dataExists = patientsCefxAConcluir.length > 0;
        break;
      case 1:
        dataExists = patientsCefxConcluidos.length > 0;
        break;
      case 2:
        dataExists = patientsCefxEmAndamento.length > 0;
        break;
      default:
        break;
    }

    return loaded && dataExists;
  });

  useEffect(() => {
    const abortController = new AbortController();

    if (cefxPatientIsLoaded) {
      setLoadingButtonCheckList(false);
      return;
    };

    if (isCefalometria && (!cefxPatientIsLoaded || updateCefxList)) {
      setLoadingButtonCheckList(true);
      setNoPatientsCefx(false);

      const { signal } = abortController;

      const fetchData = async () => {
        try {
          let allPatients = [];
          for (const currentUser of user) {
            const { idUser, idClient, hash, roles, clinicalName } = currentUser;
            let resultAllClinics = [];

            if (roles === "NAOAUTENTICADO") {
              resultAllClinics.push({ idClient, clinicalName });
            } else {
              const result = await Api.GetUserClinicaByUserId({ userId: idUser }, { signal });
              resultAllClinics = result.data;
            }

            for (const clinic of resultAllClinics) {
              let currentPage = 0;
              let totalPages = Infinity;

              while (currentPage < totalPages) {
                if (signal.aborted) return;

                const response = await Api.GetPatientListCefx(
                  { idClient, hash, page: currentPage },
                  { process_id: processo, to_conclude: toConcludeCefx },
                  { signal }
                );

                if (response.isSuccess) {
                  const { patients, totalPages: total } = response.data;

                  totalPages = total > 10 ? 10 : total;

                  if (patients.length === 0) {
                    setNoPatientsCefx(true);
                    setUpdateCefxList(false);
                  } else {
                    setNoPatientsCefx(false)
                  }

                  const patientsWithIP = patients.map(patient => ({
                    ...patient,
                    clinic: clinicalName,
                    imagem: `${API_URL}${patient.imagem}`,
                  }));

                  allPatients.push(...patientsWithIP);

                  switch (toConcludeCefx) {
                    case 0:
                      dispatch(addPatientCefxAConcluir(allPatients));
                      break;
                    case 1:
                      dispatch(addPatientCefxConcluidos(allPatients));
                      break;
                    case 2:
                      dispatch(addPatientCefxEmAndamento(allPatients));
                      break;
                    default:
                      break;
                  }

                  currentPage++;
                  setLoadingButtonCheckList(false);
                } else {
                  console.error("A requisição falhou:", response.errorMessage);
                  break;
                }
              }
            }
          }

        } catch (error) {

        } finally {
          setUpdateCefxList(false);
          // setLoadingButtonCheckList(false);
        }
      };

      dispatch(markConcludeLoaded(toConcludeCefx));
      fetchData();

      return () => {
        abortController.abort();
      };
    }
  }, [isCefalometria, toConcludeCefx, processo, updateCefxList]);

  useEffect(() => {
    if (isCefalometria) {
      const fetchDataProcess = async () => {


        try {
          for (const currentUser of user) {
            const { idClient } = currentUser;
            const processos = await Api.GetProcess(idClient);

            if (processos.isSuccess) {
              const processosAtualizados = [{ id: -1, name: "Todos" }, ...processos.data];

              const processosOrdenados = processosAtualizados.slice(1).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              });

              processosOrdenados.unshift(processosAtualizados[0]);

              setResultProcessos(processosOrdenados);
            }
          }

        } catch (error) {
          console.error(error);
        }


      };
      fetchDataProcess();

    }
  }, []);

  //Fim Pacientes CEFX

  useEffect(() => {
    if (!isCefalometria && user) {
      const controller = new AbortController();
      const { signal } = controller;

      if (
        user &&
        user.length > 0 &&
        !isClinicConnectionsComplete

      ) {
        fetchAllPatients(user, signal).catch((error) => {

          if (error.message === "Fetch aborted") {
            console.log("Fetch was aborted");
          } else {
            throw error;
          }
        });

      }

      return () => {
        controller.abort();
        localStorage.setItem("currentPageNumber", null);
        setIsClinicConnectionsComplete(false);
        setShouldAbort(false);
      };
    }
  }, [isClinicConnectionsComplete, shouldAbort]);

  useEffect(() => {
    if (lastPage && tempPatients?.length > 0 && !isCefalometria) {
      setLoadingNextPage(false);
      const patientsResults = getFirstIndex();

      setPatientList((prevPatientList) => [
        ...prevPatientList,
        ...patientsResults.filter(
          (patient) =>
            !prevPatientList.some(
              (existingPatient) => existingPatient.id === patient.id
            )
        ),
      ]);
    } else if (
      lastPage &&
      tempPatients.length === 0 &&
      !isClinicConnectionsComplete
    ) {
      setLoadingNextPage(true);
    } else if (tempPatients.length === 0 || isClinicConnectionsComplete) {
      setLoadingNextPage(false);
    }
  }, [lastPage, tempPatients, isClinicConnectionsComplete]);

  useEffect(() => {
    if (!isCefalometria) {
      if (patientList.length > 0) {
        dispatch(setpatients(patientList));
      }
    }
  }, [patientList]);

  return (
    <PatientContext.Provider
      value={{
        patientList,
        setPatientList,
        filteredPatientList,
        setFilteredPatientList,
        loadingNextPage,
        setLoadingNextPage,
        lastPage,
        setLastPage,
        resetPatientLoading,
        reactivePatientsLoading,
        loadingButtonChecklist,
        setLoadingButtonCheckList,
        noPatientsCefx,
        setNoPatientsCefx,
        resultProcessos,
        setUpdateCefxList,
        updateCefxList,
        reorderedClinics,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
