import { ErrorInfo, useEffect, useState, useRef } from "react";
import { Input } from "../../components/Form/Input";
import { Formik, Form, FormikValues } from "formik";
import { Button, Text } from "@cdt-ui/react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";

import styles from "./styles.module.css";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { InputMask } from "../../components/Form/InputMask";
import { ModalErrorOrSucessMessage } from "../../components/ModalErrorOrSucessMessage";
import axios from "axios";
import { Loading } from "../../components/Loading";
import { FloppyDisk, X } from "@phosphor-icons/react";

import React from "react";
import { Card } from "./Card";
import { NoClassempty } from "../Clinic/components/NoClassempty";

export function Notifications() {
  const [notificationDetails, setNotificationDetails] = useState<any>([]);
  const [allNotifications, setAllNotifications] = useState(true);
  const { user } = useSelector(selectUser);

  const getNotificationDetails = async () => {
    const idUser = user[0]?.idUser;

    try {
      const result = await Api.getnotificationById(idUser);

      if (result.isSuccess === true) {
        setNotificationDetails(result.data);
      } else {
        console.error(
          "A requisição não foi bem-sucedida:",
          result.errorMessage
        );
      }
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
    }
  };

  useEffect(() => {
    getNotificationDetails();
  }, []);
  const handleAllNotifications = () => {
    setAllNotifications(!allNotifications);
  };
  console.log(notificationDetails, "notificationDetails")


  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <Text className={styles.topTitle}>Notificações</Text>
        <div className={styles.topCard}>
          {allNotifications ? (
            <div className={styles.topDiv}>
              <Text
                style={{
                  color: "#007E69",
                }}
              >
                Todas
                <div
                  style={{
                    alignSelf: "stretch",
                    height: 2,
                    background: "#007E69",
                    borderRadius: 16,
                  }}
                />
              </Text>
              <Text
                className={styles.topNo}
                onClick={() => handleAllNotifications()}
              >
                Não lidas
                <div
                  style={{
                    alignSelf: "stretch",
                    height: 2,
                    background: "#8E8E8E",
                    borderRadius: 16,
                  }}
                />
              </Text>
            </div>
          ) : (
            <div className={styles.topDiv}>
              <Text onClick={() => handleAllNotifications()}>
                Todas
                <div
                  style={{
                    alignSelf: "stretch",
                    height: 2,
                    background: "#8E8E8E",
                    borderRadius: 16,
                  }}
                />
              </Text>
              <Text
                style={{
                  color: "#007E69",
                }}
              >
                Não lidas
                <div
                  style={{
                    alignSelf: "stretch",
                    height: 2,
                    background: "#007E69",
                    borderRadius: 16,
                  }}
                />
              </Text>
            </div>
          )}
        </div>
      </div>
      {/* <div style={{ width: "100%", height: 34, background: "#F8F8F8" }}>
        <div
          style={{
            color: "#535353",
            fontSize: 14,
            fontFamily: "Segoe UI Variable",
            fontWeight: "600",
            paddingTop: 7,
            paddingLeft: 10,
          }}
        >
          HOJE
        </div>
      </div> */}
      {notificationDetails && notificationDetails.length > 0 ? (
        allNotifications ? (
          notificationDetails.map((item, index) => (
            <Card key={index} notification={item.notification} info={item} />
          ))
        ) : (
          notificationDetails
            .filter((item) => !item.read)
            .map((item, index) => (
              <Card key={index} notification={item.notification} info={item} />
            ))
        )
      ) : (
        <NoClassempty />
      )}
    </div>
  );
}
