import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import {
  EllipsisVerticalIcon,
  StarIcon,
  PlusIcon,
  PencilIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";

import { getAllModels, updateModel, getModel } from "../utils/apiConnection";
import { SearchInput } from "../components/SearchInput";

import { useMediaQuery } from "react-responsive";

interface SelectModelsProps {
  idUser: number;
  quill: any;
  removeExtraSpaces: (q: any, topSpaces: number, bottomSpaces: number) => void;
  replacePatientText: () => void;
  selectedPatient: any;
  wasModelUpdated: boolean;
  setModelUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectModels = ({
  idUser,
  quill,
  removeExtraSpaces,
  replacePatientText,
  selectedPatient,
  wasModelUpdated,
  setModelUpdated,
}: SelectModelsProps) => {
  const [modelsData, setModelsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredModelsData, setFilteredModelsData] = useState(modelsData);
  const [activeOptionIndex, setActiveOptionIndex] = useState(null);
  const isTabletDimension = useMediaQuery({query: '(max-width: 1024px)'});
  const maxCardsToShow = isTabletDimension ? 1 : 4;
  const navigate = useNavigate();

  useEffect(() => {
    getAllModels(idUser, setModelsData, setFilteredModelsData);
    setModelUpdated(false);
  }, [wasModelUpdated]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);

    const filteredData = modelsData.filter((model) =>
      model.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    setFilteredModelsData(filteredData);
  };

  const handleFavoriteClick = async (id: number) => {
    const modelData = await getModel(id);
    modelData.isFavorite
      ? (modelData.isFavorite = false)
      : (modelData.isFavorite = true);
    await updateModel(id, modelData, setModelUpdated);
  };

  const handleHideModel = async (id: number) => {
    const modelData = await getModel(id);
    modelData.isHidden = true;
    await updateModel(id, modelData, setModelUpdated);
  };

  const handleOptionActive = (id: number) => {
    if (id === activeOptionIndex) {
      setActiveOptionIndex(null);
    } else {
      setActiveOptionIndex(id);
    }
  };

  const handleOptionDesative = (id) => {
    if (activeOptionIndex === id) {
      handleOptionActive(id);
    }
  };

  const customSort = (a, b) => {
    if (a.isFavorite && !b.isFavorite) {
      return -1;
    }

    if (!a.isFavorite && b.isFavorite) {
      return 1;
    }

    return a.id - b.id;
  };

  const isHidden = (id: number) => {
    if (filteredModelsData[id]) {
      return filteredModelsData[id].isHidden;
    }
  };

  const limitedModelsData = filteredModelsData
    .filter((model) => !model.isHidden)
    .sort(customSort)
    .slice(0, maxCardsToShow);

  const handleModelRenderization = (content) => {
    if (!quill) return;
    quill.root.innerHTML = content;
    setTimeout(() => removeExtraSpaces(quill, 2, 1), 100);
    replacePatientText();
  };

  const redirectToModelsList = () => {
    navigate("/dashboard/lista-de-modelos");
  };

  const handleEditModel = (id: number) => {
    navigate(`/dashboard/requisição-exames/editar-modelo/${id}`);
  };

  return (
    <div>
      <p className={styles.title}>Modelos</p>
      <SearchInput
        placeholder="Buscar modelo de requisição"
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
      />
      {modelsData.length > 0 ? (
        <div className={styles.containerModelsCard}>
          {limitedModelsData.map((model) => (
            <div
              className={`${styles.modelCard} ${
                model.isFavorite ? styles.favoriteCard : ""
              }`}
              key={model.id}
              onClick={() => {
                handleOptionDesative(model.id);
                handleModelRenderization(model.html);
              }}
              onMouseLeave={() => handleOptionDesative(model.id)}
            >
              {model.name}
              <div className={`${styles.modelCardIcons}`}>
                <StarIcon
                  width={20}
                  fill={model.isFavorite ? "#007E69" : "white"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFavoriteClick(model.id);
                  }}
                />
                <EllipsisVerticalIcon
                  width={20}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOptionActive(model.id);
                  }}
                />
              </div>
              {activeOptionIndex === model.id && (
                <div className={styles.optionCard}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditModel(model.id);
                    }}
                  >
                    <PencilIcon width={20} />
                    Editar Modelo
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHideModel(model.id);
                    }}
                  >
                    <EyeIcon color="white" width={20} />
                    Ocultar Modelo
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p color="#007E69">
          Nenhum modelo foi criado ainda. Clique no botão salvar como modelo
          para adicionar.
        </p>
      )}

      <div className={styles.containerButtonViewModels}>
        <button
          onClick={redirectToModelsList}
          className={styles.buttonViewModels}
        >
          <PlusIcon width={20} />
          Ver todos modelos
        </button>
      </div>
    </div>
  );
};
