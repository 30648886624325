import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useContext } from "react";

import { format } from "date-fns";
import { ShareNetwork } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

import { selectUser } from "../../store/slices/userSlice";
import { selectPatientsList } from "../../store/slices/patientListSlice";
import { clearPatientCefxAConcluir, selectPatientsCefxAConcluir } from "../../store/slices/patientCefxAConcluirSlice";
import { clearPatientCefxConcluidos, selectPatientCefxConcluidos } from "../../store/slices/patientCefxConcluidosSlice";
import { clearPatientCefxEmAndamento, selectPatientsCefxEmAndamento } from "../../store/slices/patientCefxEmAndamentoSlice";
import { PatientContext } from "./PatientProvider";

import { BoxVideo } from "../../components/BoxVideo";
import { Filters } from "../../components/Filters";
import { Pagination } from "../../components/Pagination";
import { Table } from "../../components/Table";
import { ModalVideoPopup } from "../../components/ModalVideoPopup";
import { dateFormatter } from "../../utils/formatter";
import { Loading } from "../../components/Loading";
import { PatientImage } from './PatientImage';

import styles from "./styles.module.css";

import { useMediaQuery } from "react-responsive";
import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { selectProcesso, setProcesso } from "../../store/slices/processoSlice";
import { selectToConcludeCefx, setToConcludeCefx } from "../../store/slices/toConcludeCefxSlice";
import { clearAllConcludesLoaded } from "../../store/slices/loadedConcludesSlices";
import PWAInstallerPrompt from 'react-pwa-installer-prompt';
import { selectisClinicConnectionsComplete } from "../../store/slices/isClinicConnectionsCompleteSlice";
import { NavigationChecklist } from "./CheckListCefx/NavigationChecklist/NavigationChecklist";
interface PatientProps {
  Id: number;
  SeqAtend: number;
  Paciente: string;
  Status: string;
  DataAtend: string;
  DataNasc: string;
  Idade: string;
  Indicacao: string;
  IdPaciente: number;
  Sexo: string;
  Telefone: string | null;
  Imagem: string;
  TodosExames: any[] | null;
  ImagensPaciente: any[] | null;
  Exames: any[] | null;
  Cefalometrias: any[] | null;
  ControleProcessos: any[] | null;
  VideosPaciente: any[] | null;
  TipoUsuario: string | null;
  SS: string;
}

interface newPatientProps {
  id?: number;
  name?: string;
  ss?: string;
  seqAtend?: string;
  idClient: string;
  description: any[];
  idPacient: number;
}

interface PatientListProps {
  sidebarCollapse: boolean;
}

export function PatientList({ sidebarCollapse }: PatientListProps) {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector(selectUser);
  const { patients } = useSelector(selectPatientsList);
  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { processo } = useSelector(selectProcesso);
  const { isClinicConnectionsComplete } = useSelector(selectisClinicConnectionsComplete);

  const { patientsCefxAConcluir } = useSelector(selectPatientsCefxAConcluir);
  const { patientsCefxConcluidos } = useSelector(selectPatientCefxConcluidos);
  const { patientsCefxEmAndamento } = useSelector(selectPatientsCefxEmAndamento);
  const { toConcludeCefx } = useSelector(selectToConcludeCefx);

  let totalPages = Number(localStorage.getItem("totalPages"));
  let pageLastRequest = Number(localStorage.getItem("page"));
  let page = pageLastRequest;

  const [isModalOpen, setModalOpen] = useState(true);
  const [listUsers, setListUsers] = useState({
    data: [],
  });
  const [resultSearch, setResultSearch] = useState([]);
  const [resultRecommendation, setResultRecommendation] = useState([]);
  const [noResultSearch, setNoResultSearch] = useState(false);
  const [scrollto, setScrollto] = useState(0);
  const [lastArrowChanged, setLastArrowChanged] = useState({
    prop: "dataAtend",
    type: "down",
  });
  const [notFound, setNotFound] = useState(false);
  const [prevURL, setPrevURL] = useState("");
  const [buttonChecklist, setButtonCheckList] = useState(null);

  const {
    patientList,
    setPatientList,
    resetPatientLoading,
    filteredPatientList,
    setFilteredPatientList,
    loadingNextPage,
    setLastPage,
    loadingButtonChecklist,
    setLoadingButtonCheckList,
    noPatientsCefx,
    setNoPatientsCefx,
    resultProcessos,
    setUpdateCefxList,
    updateCefxList,

  } = useContext(PatientContext);
  
  const toConcludeStorageMap = {
    null: [],
    '0': patientsCefxAConcluir,
    '1': patientsCefxConcluidos,
    '2': patientsCefxEmAndamento,
  };

  let patientCefxListData = resultSearch?.length > 0 ? resultSearch : toConcludeStorageMap[toConcludeCefx];

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const formatarDataHora = (dataString: string) => {
    const dataObj = new Date(dataString);
    return format(dataObj, "dd/MM/yyyy HH:mm:ss");
  };

  const handleNoShowAgain = () => {
    localStorage.setItem("showModal", "false");
    handleCloseModal();
  };

  const handleGetPatientsSeach = (patientSearch: []) => {
    setResultSearch(patientSearch);
  };

  const isMobileScreen = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  const updateListUser = (data: PatientProps[]) => {
    let newPatient: newPatientProps[] = [];

    if (data.length > 0) {
      data.map((item: any) => {
        // const OpenWhatsapp = (e) => {
        //   e.preventDefault();
        //   e.stopPropagation();
        //   var numeros = item.telefone.replace(/\D/g, "");
        //   var numeroDesejado = numeros.substring(0, 10);
        //   window.location.href = "https://wa.me/55" + numeroDesejado;
        // };
        newPatient.push({
          description: [
            <PatientImage id={item?.id} imagem={item?.imagem} paciente={item?.paciente} />,
            formatarDataHora(item?.dataAtend),
            item?.idade,
            item?.indicacao,
            item.clinic,
            dateFormatter.format(new Date(item?.dataAtend)),
            item?.status,
            <ShareNetwork
              size={20}
              color="#007E69"

              style={{ cursor: "pointer" }}
            />,
          ],
          id: item?.id,
          ss: item?.ss,
          name: item?.paciente,
          seqAtend: item?.seqAtend,
          idClient: item?.idClient,
          idPacient: item?.idPaciente
        });
      });
    }
    setListUsers({ ...listUsers, data: newPatient });
  };

  const handleResetListPatients = () => {
    setResultSearch([]);
    setPatientList(patients);
    setNoResultSearch(false);
  };

  const filterBySituation = (filteredPatients: PatientProps[]) => {
    setPatientList(filteredPatients);
  };

  const getScrollTo = (valueScroll: number) => {
    if (!!valueScroll) {
      // setScrollto(valueScroll);
      // window.scrollTo(0, valueScroll);
    }
  };

  const getLastPage = (lastPage: boolean) => {
    setLastPage(lastPage);
  };

  const handleChangeProcessos = (event) => {
    const value = event.target.value;
    setLoadingButtonCheckList(true);

    patientCefxListData = [];
    dispatch(clearPatientCefxAConcluir());
    dispatch(clearPatientCefxConcluidos());
    dispatch(clearPatientCefxEmAndamento());
    dispatch(clearAllConcludesLoaded());

    const url = "/dashboard/lista-pacientes";
    const params = new URLSearchParams(window.location.search);
    params.set("page", "1");
    const newUrl = `${url}?${params.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);

    const idSelecionado = value ? parseInt(value) : -2;
    dispatch(setProcesso(idSelecionado));
  };

  const toggleChecklist = (buttonChecklistId: string) => {
    setNoPatientsCefx(false);

    const url = "/dashboard/lista-pacientes";
    const params = new URLSearchParams(window.location.search);
    params.set("page", "1");
    const newUrl = `${url}?${params.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);

    switch (buttonChecklistId) {
      case 'checklistAConcluir':
        dispatch(setToConcludeCefx(0));
        break;
      case 'checklistConcluidos':
        dispatch(setToConcludeCefx(1));
        break;
      case 'checklistEmAndamento':
        dispatch(setToConcludeCefx(2));
        break
    }
  };

  const newCefxRequestUpdateButton = () => {
    patientCefxListData = []

    dispatch(clearPatientCefxAConcluir());
    dispatch(clearPatientCefxConcluidos());
    dispatch(clearPatientCefxEmAndamento());
    dispatch(clearAllConcludesLoaded());

    setUpdateCefxList(true);

    const url = "/dashboard/lista-pacientes";
    const params = new URLSearchParams(window.location.search);
    params.set("page", "1");
    const newUrl = `${url}?${params.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }

  const updateList = () => {
    resetPatientLoading()
    setFilteredPatientList([]);

    const url = "/dashboard/lista-pacientes";
    const params = new URLSearchParams(window.location.search);
    params.set("page", "1");
    const newUrl = `${url}?${params.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }

  useEffect(() => {
    const shouldShowModal = localStorage.getItem("showModal");
    if (shouldShowModal === "false") {
      setModalOpen(false);
    }
  }, []);

  useEffect(() => {
    if (resultSearch.length > 0) {
      history(`/dashboard/lista-pacientes?page=1`);
    }
  }, [resultSearch]);

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.container_filterAndVideo}>
          <Filters
            handleGetPatientsSeach={handleGetPatientsSeach}
            resultSearch={resultSearch}
            setResultSearch={setResultSearch}
            setResultRecommendation={setResultRecommendation}
            handleResetListPatients={handleResetListPatients}
            setFilteredPatientList={setFilteredPatientList}
            patientList={patients || patientCefxListData}
            filteredPatientList={filteredPatientList}
            lastArrowChanged={lastArrowChanged}
            setLastArrowChanged={setLastArrowChanged}
            setNotFound={setNotFound}
            listUser={listUsers}
            updateListUser={updateListUser}
            prevURL={prevURL}
            setPrevURL={setPrevURL}
            setNoResultSearch={setNoResultSearch}
            handleChangeProcessos={handleChangeProcessos}
            resultProcessos={resultProcessos}
            processo={processo}
            newRequestUpdateButton={ isCefalometria ? newCefxRequestUpdateButton : updateList}
            updateCefxList={updateCefxList}
          />

          {
            isCefalometria && (
              <NavigationChecklist
                toggleChecklist={toggleChecklist}
              />
            )
          }

          {
            loadingButtonChecklist && isCefalometria && !noPatientsCefx ? (
              <p className={styles.textLoadingCefx}>
                Por favor, Aguarde...
              </p>
            ) : noPatientsCefx && isCefalometria ? (
              <div className={styles.containerTextUserFalse}>
                <p>Nenhum paciente disponível.</p>
              </div>
            ) : patientList?.length !== 0 || patientCefxListData?.length !== 0 ||
              (filteredPatientList?.length !== 0 && user !== false) ? (
              <Table
                data={{
                  patientList: isCefalometria ? patientCefxListData : filteredPatientList
                }}
                list={listUsers}
                getScrollTo={getScrollTo}
                setLastArrowChanged={setLastArrowChanged}
                noResultSearch={noResultSearch}
              />
            ) : user === undefined || user === false ? (
              <div className={styles.containerTextUserFalse}>
                <p>Sem pacientes no momento.</p>
              </div>
            ) : !isCefalometria && (
              <Loading text="Por favor, Aguarde..." isMobileListPacient />
            )
          }

          <div className={styles.containerPagination}>
            <Pagination
              items={isCefalometria ? patientCefxListData : resultSearch.length > 0 ? resultSearch : filteredPatientList}
              listUsers={listUsers}
              setListItems={updateListUser}
              getLastPage={getLastPage}
              loadingNextPage={isCefalometria ? false : loadingNextPage}
              setPrevURL={setPrevURL}
            />
          </div>
        </div>

        <BoxVideo
          sidebarCollapse={sidebarCollapse}
        />
      </div>

      {isModalOpen && !isMobileScreen && (
        <ModalVideoPopup
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          onNoShowAgain={handleNoShowAgain}
        />
      )}
    </section>
  );
}
