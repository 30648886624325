import { useEffect, useRef, useState } from "react";
import { Input } from "../../../components/Form/Input";
import { Formik, Form, FormikValues } from "formik";
import { Button } from "@cdt-ui/react";
import * as yup from "yup";

import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { InputMask } from "../../../components/Form/InputMask";

import styles from "./styles.module.css";
import React from "react";
import { CustomSelect } from "../../../components/CustomSelect";
import { CheckboxEmail } from "../../../components/Form/Checkbox/NotificacaoPorEmail";
import { CheckboxTermosDeUso } from "../../../components/Form/Checkbox/AceitarTermos";
import { useNavigate } from "react-router-dom";
import { selectUser, setUser } from "../../../store/slices/userSlice";
import Api from "../../../helpers/Api";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { ModalErrorOrSucessMessage } from "../../../components/ModalErrorOrSucessMessage";
import { setIsClinicConnectionsComplete } from "../../../store/slices/isClinicConnectionsCompleteSlice";

interface Step2Props {
  getPayload: (values: any) => void;
  handleBackButtonStep1: () => void;
  userDetailsStep2: any;
  payloadStep2: any;
  payloadStep1: any;
}

export function Step2({
  getPayload,
  handleBackButtonStep1,
  userDetailsStep2,
  payloadStep2,
  payloadStep1,
}: Step2Props) {
  const formRef = useRef<any>();
  const [dataAPICep, setDataAPICep] = useState<any>(null);
  const [cityOptions, setCityOptions] = useState([{ value: " ", label: " " }]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isModalErroOrSucessOpen, setIsModalErroOrSucessOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector(selectUser);

  const handleCloseModalErrorOrSucess = () => {
    setIsModalErroOrSucessOpen(false);
  };

  const navigateToDashboardScreen = () => {
    if (user !== false) {
      navigate("/dashboard");
      setIsClinicConnectionsComplete(false);
    } else {
      navigate("/");
      setIsClinicConnectionsComplete(false);
    }
  };

  const validationSchema = yup.object().shape({
    cep: yup.string().required("Campo obrigatório"),
    estado: yup.string().required("Campo obrigatório"),
    cidade: yup.string().required("Campo obrigatório"),
    endereco: yup.string().required("Campo obrigatório"),
    numeroEndereco: yup.string().required("Campo obrigatório"),
    agreeToTerms: yup.string().required("Obrigatório aceitar os Termos de Uso e Privacidade"),
    complemento: yup.string(),
    foneSms: yup.string().required("Campo obrigatório"),
    emailAtendimento: yup.string().required("Campo obrigatório"),
  });

  useEffect(() => {
    if (payloadStep2) {
      formRef.current.setFieldValue("cep", payloadStep2?.cep);
      formRef.current.setFieldValue("estado", payloadStep2?.estado);
      fetchCities(payloadStep2?.estado);
      formRef.current.setFieldValue("cidade", payloadStep2?.cidade);
      formRef.current.setFieldValue("endereco", payloadStep2?.endereco);
      formRef.current.setFieldValue(
        "numeroEndereco",
        payloadStep2?.numeroEndereco
      );
      formRef.current.setFieldValue("complemento", payloadStep2?.complemento);
      formRef.current.setFieldValue("foneSms", payloadStep2?.foneSms);
      formRef.current.setFieldValue(
        "emailAtendimento",
        payloadStep2?.emailAtendimento
      );
      setDataAPICep(payloadStep2?.cep)
    }
  }, [payloadStep2]);

  const handleVoltar = () => {
    const values = formRef.current?.values;

    if (values) {
      getPayload(values);
    }

    handleBackButtonStep1();
  };

  const handleSubmit = async (values: FormikValues) => {
    const { agreeToTerms } = values;

    if (values && agreeToTerms) {
      getPayload(values);
      setIsLoadingSubmit(true);

      const { emailAtendimento, numeroEndereco, foneSms, complemento, endereco, cidade, estado, cep, agreeToTerms } = values;
      const idClinica = user && user.length > 0 ? Number(user[0].idClient) : 0;

      const transformedPayload = {
        pais: "Brasil",
        ...payloadStep1,
        idClinica: idClinica,
        cep: cep,
        estado: estado,
        cidade: cidade,
        endereco: endereco,
        complemento: complemento ? complemento : '',
        numeroEndereco: Number(numeroEndereco),
        foneSms: foneSms,
        emailAtendimento: emailAtendimento,
      };

      if (agreeToTerms) {
        try {
          const userResult = await Api.CreateUser(transformedPayload);

          if (userResult.isSuccess === true && user !== false) {
            const trimmedValues = {
              logon: payloadStep1.logon.trim(),
              password: payloadStep1.senha.trim(),
              idClient: user[0].idClient,
            };

            for (let i = 0; i < user.length; i++) {
              const userData = user[i];
              await Api.postClinic({
                id: 0,
                idClient: userData.idClient,
                clinicalName: userData.clinicalName,
                logon: userData.logon,
                senha: userData.senha,
                address: "",
                city: "",
                neighborhood: "",
                hash: userData.hash,
                idUser: userResult.data.id,
              });
            }

            dispatch(setUser(false));

            const result = await Api.SignIn(trimmedValues);

            if (result.isSuccess === true) {
              const clienteId = result.data[0].idClient;
              const getIP = await Api.GetIP(clienteId);

              result.data[0].ip = getIP;

              dispatch(setUser(result.data));
              setIsModalErroOrSucessOpen(true);
              setIsClinicConnectionsComplete(false);
            } else {
              console.log("error");
            }
          } else if (userResult.isSuccess === true) {
            toast(userResult.message, {
              style: {
                background: "#008a00",
                color: "#fff",
              },
            });
            setIsModalErroOrSucessOpen(true);
            setIsClinicConnectionsComplete(false);
          } else {
            toast(userResult.message, {
              style: {
                background: "#f09500",
                color: "#fff",
              },
            });
          }
        } catch (error) {
          toast("Error ao salvar", {
            style: {
              background: "#e71010",
              color: "#fff",
            },
          });
          console.error(error);
          setIsLoadingSubmit(false);
        } finally {
          setIsLoadingSubmit(false);
          setIsClinicConnectionsComplete(false);
        }
      }
    }
  };

  function onBlurCep(ev, setFieldValue) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setDataAPICep(data);
        setFieldValue("estado", data.uf);
        fetchCities(data.uf);
        setFieldValue("cidade", data.localidade);
        setFieldValue("endereco", data?.logradouro);
      })
      .catch((error) => {
        console.error("Error fetching CEP data:", error);
      });
  }

  const fetchCities = (estado: string) => {
    setLoading(true);
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/distritos`
    )
      .then((res) => res.json())
      .then((data) => {
        const cities = data.map((item) => ({
          label: item.nome,
          value: item.nome,
        }));
        setCityOptions(cities);
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      })
      .catch((error) => {
        console.error("Error fetching cities data:", error);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const onChangeState = () => {
    const { estado } = formRef.current.values;
    fetchCities(estado);
  };

  const stateOptions = [
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" },
  ];


  return (
    <div className={styles.containerStep2}>
      <div className={styles.headerStep2}>
        <div>
          <p style={{ color: "#74CFC3", fontWeight: "600", marginBottom: 5 }}>
            1. Dados Pessoais
          </p>
          <div
            style={{
              height: 6,
              backgroundColor: "#74CFC3",
              borderRadius: 20,
            }}
          />
        </div>

        <div>
          <p style={{ color: "#008F79", fontWeight: "700", marginBottom: 5 }}>
            2. Endereço e Contato
          </p>
          <div
            style={{
              height: 6,
              backgroundColor: "#008F79",
              borderRadius: 20,
            }}
          />
        </div>
      </div>

      <div className={styles.formStep2}>
        <p
          style={{
            color: "#7B889C",
            fontWeight: "700",
            marginBottom: 30,
            fontSize: 14,
          }}
        >
          ENDEREÇO
        </p>

        <div className={styles.inputsStep2}>
          <Formik
            initialValues={{
              cep: "",
              estado: "",
              cidade: "",
              endereco: "",
              numeroEndereco: "",
              complemento: "",
              foneSms: "",
              emailAtendimento: "",
              enviaEmail: "",
              agreeToTerms: "",
            }}
            innerRef={formRef}
            onSubmit={(values) => handleSubmit(values)}
            validateOnChange={false}
            validateOnBlur={true}
            validationSchema={validationSchema}
          >
            {({
              errors,
              touched,
              isSubmitting,
              values,
              setFieldValue,
              handleChange,
            }) => (
              <Form>
                <div className={styles.containerInputs}>
                  <InputMask
                    type="text"
                    title="CEP"
                    onBlur={(ev) => {
                      onBlurCep(ev, setFieldValue);
                    }}
                    name="cep"
                    value={values.cep}
                    mask="00000-000"
                    onChange={handleChange}
                    placeholder="00000-000"
                    labelStyleAbsolute
                    error={
                      touched.cep && !values.cep
                        ? "Campo obrigatório"
                        : errors.cep
                    }
                  />

                  <div className={styles.wrapperInput}>
                    <CustomSelect
                      name="estado"
                      placeholder="Selecione um estado"
                      label="Estado"
                      options={stateOptions}
                      values={values}
                      /* onBlur={onBlurState} */
                      onChange={onChangeState}
                      value={stateOptions.find(
                        (option) => option.value === values.estado
                      )}
                    />

                    <CustomSelect
                      name="cidade"
                      placeholder="Selecione uma cidade"
                      label="Cidade"
                      options={cityOptions}
                      values={values}
                      value={cityOptions.find(
                        (option) => option.value === values.cidade
                      )}
                      isLoading={isLoading}
                    />
                  </div>
                </div>

                <Input
                  name="endereco"
                  type="text"
                  title="Endereço"
                  placeholder="Ex: Rua José de Freitas"
                  /* value={dataAPICep !== null ? String(dataAPICep?.logradouro) : ''} */
                  error={errors.endereco}
                />

                <div
                  className={styles.containerInputs}
                  style={{ marginTop: "10px" }}
                >
                  <Input
                    name="numeroEndereco"
                    type="text"
                    title="Número"
                    placeholder="00"
                    noRequired
                    onChange={(e) => {
                      let inputNumero = e.target.value;

                      inputNumero = inputNumero
                        .replace(/\D/g, "")
                      setFieldValue("numeroEndereco", inputNumero);
                    }}
                    error={errors.numeroEndereco}
                  />

                  <Input
                    name="complemento"
                    type="text"
                    title="Complemento"
                    placeholder="Ex: Na esquina do mercado"
                    noRequired
                    error={errors.complemento}
                  />
                </div>

                <div className={styles.borderStyle} />

                <p
                  style={{
                    fontSize: 14,
                    color: "#7B889C",
                    fontWeight: "700",
                    marginBottom: 12,
                    marginTop: 8,
                  }}
                >
                  CONTATO
                </p>

                <div className={styles.containerInputs}>
                  <InputMask
                    type="text"
                    title="Celular"
                    name="foneSms"
                    value={values.foneSms}
                    onChange={handleChange}
                    mask="(00) 00000-0000"
                    placeholder="(00) 00000-0000"
                    labelStyleAbsolute
                    error={errors.foneSms}
                  />

                  <Input
                    name="emailAtendimento"
                    type="email"
                    title="E-mail"
                    placeholder="exemplo@exemplo.com"
                    error={errors.emailAtendimento}
                  />
                </div>


                <div className={styles.borderStyle} />

                <div className={styles.containerCheckbox}>
                  {/* <CheckboxEmail
                    name="enviaEmail"
                    customStyle={{ width: "18px", height: "18px" }}
                    error={errors.enviaEmail}
                  /> */}

                  <CheckboxTermosDeUso
                    customStyle={{ width: "18px", height: "18px" }}
                    name="agreeToTerms"
                    error={errors.agreeToTerms}
                  />
                </div>


                <div className={styles.container_buttons}>
                  <div className={styles.buttonCancelar}>
                    <Button
                      type="button"
                      size="full"
                      variant="tertiary"
                      onClick={handleVoltar}
                    >
                      <ArrowLeftIcon width={20} height={20} />

                      <span> Voltar</span>
                    </Button>
                  </div>

                  <div className={styles.buttonContinuar}>
                    <Button
                      type="submit"
                      title={!values?.agreeToTerms && 'Obrigatório aceitar os Termos de Uso e Privacidade'}
                      size="full"
                      variant="greenDark"
                      disabled={isLoadingSubmit || !values?.agreeToTerms}
                    >
                      {isLoadingSubmit ? (
                        <div className={styles.container_buttons_loading}>
                          Cadastrando...
                        </div>
                      ) : (
                        <>
                          <span>Salvar</span>

                          <ArrowRightIcon />
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {isModalErroOrSucessOpen && (
        <ModalErrorOrSucessMessage
          type="success"
          isOpen={isModalErroOrSucessOpen}
          handleClose={handleCloseModalErrorOrSucess}
          onRequestClose={handleCloseModalErrorOrSucess}
          handleToNavigate={navigateToDashboardScreen}
        />
      )}
    </div>
  );
}
