import Modal from "react-modal";
import { Button } from "@cdt-ui/react";

import styles from "./styles.module.css";

import {
  CheckCircleIcon,
  ArrowLeftOnRectangleIcon,
  XMarkIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon, XCircleIcon } from "@heroicons/react/24/solid";

interface ModalErrorOrSucessMessageProps {
  type: string;
  isOpen: boolean;
  onRequestClose: () => void;
  formValues?: () => void;
  handleClose: () => void;
  handleToNavigate?: () => void;
  customTItle?: boolean;
  customDescription?: string;
}

export function ModalErrorOrSucessMessage({
  customTItle,
  type,
  isOpen,
  onRequestClose,
  handleToNavigate,
  formValues,
  handleClose,
  customDescription
}: ModalErrorOrSucessMessageProps) {
  let modalClassName = `${styles.modal}`;
  let icon;
  let buttons;
  let title;
  let description;

  if (type === "success") {
    modalClassName += ` ${styles.success}`;
    title = customTItle
      ? "Dados salvos com sucesso!"
      : "Cadastro realizado com sucesso!";
    description = customTItle
      ? ""
      : "Confirme o link em seu e-mail para acessar a plataforma!";
    icon = <CheckCircleIcon width={22} />;
    buttons = (
      <div className={styles.buttonContainer}>
        <button
          type="button"
          className={`${styles.button} ${styles.buttonSucess}`}
          onClick={handleToNavigate}
        >
          OK, entendi
        </button>
      </div>
    );
  } else if (type === "fault") {
    modalClassName += ` ${styles.fault}`;
    title = "Title";
    description = "Auxiliary Text";
    icon = <PencilSquareIcon width={22} />;
    buttons = (
      <div className={styles.buttonContainer}>
        <button className={styles.button} type="button">
          Tentar novamente
        </button>
      </div>
    );
  } else if (type === "error") {
    modalClassName += ` ${styles.error}`;
    title = "Cancelar alterações?";
    description = "As alterações serão excluídas permanentemente";
    icon = <PencilSquareIcon width={22} />;
    buttons = (
      <div className={styles.buttonContainer}>
        <button
          className={styles.backButton}
          onClick={handleClose}
          type="button"
        >
          Voltar
        </button>
        <button className={styles.cancelButton} onClick={formValues}>
          Sim,cancelar
        </button>
      </div>
    );
  } else if (type === "errorEmail") {
    modalClassName = `${styles.error}`;
    title = !customTItle ? "Falha ao enviar e-mail" : 'Falha ao atualizar senha';
    description = !customDescription
      ? "Não encontramos uma conta associada ao e-mail. Verifique se está correto e tente novamente"
      : customDescription;
    icon = <XCircleIcon width={32} />;
    buttons = (
      <div className={styles.buttonContainer} onClick={handleClose}>
        <button className={styles.button} type="button">
          Tentar novamente
        </button>
      </div>
    );
  } else if (type === "sentEmail") {
    modalClassName += ` ${styles.success}`;
    title = "Email enviado com sucesso!";
    description = "Confirme o link em seu e-mail para alterar a senha!";
    icon = <CheckCircleIcon width={22} />;
    buttons = (
      <div className={styles.buttonContainer}>
        <button
          type="button"
          className={`${styles.button} ${styles.buttonSucess}`}
          onClick={onRequestClose}
        >
          OK, entendi
        </button>
      </div>
    );
  } else if (type === "successExamsRequest") {
    modalClassName += ` ${styles.success}`;
    title = "Ação realizada com sucesso!";
    description = "A ação envolvendo a requisição de exames foi concluída sem problemas.";
    icon = <CheckCircleIcon width={22} />;
    buttons = (
      <div className={styles.buttonContainer}>
        <button
          type="button"
          className={`${styles.button} ${styles.buttonSucess}`}
          onClick={onRequestClose}
        >
          Continuar
        </button>
      </div>
    );
  } else if (type === "errorExamsRequest") {
    modalClassName = `${styles.error}`;
    title = "Falha com a requisição"
    description = "Houve um erro ao salvar/enviar a requisição de exames!"
    icon = <XCircleIcon width={32} />;
    buttons = (
      <div className={styles.buttonContainer} onClick={handleClose}>
        <button className={styles.button} type="button">
          Tentar novamente
        </button>
      </div>
    );
  } else if (type === "errorAboveLimit") {
    modalClassName = `${styles.error}`;
    title = "Quantidade de linhas ultrapassadas";
    description = "Apague espaços em brancos desnecessários para realizar a ação.";
    icon = <ExclamationTriangleIcon width={32} />;
    buttons = (
      <div className={styles.buttonContainer} onClick={handleClose}>
        <button className={styles.button} type="button">
          Tentar novamente
        </button>
      </div>
    );
  } else if (type === "mobileWarning") {
    modalClassName = `${styles.error}`;
    title = "Tela indisponível";
    description = "Visualização de objetos 3D apenas na versão desktop!";
    icon = <ExclamationTriangleIcon width={32} />;
    buttons = (
      <div className={styles.buttonContainer} onClick={handleClose}>
        <button className={styles.button} type="button">
          Ok, entendi
        </button>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleToNavigate ? handleToNavigate : onRequestClose}
      overlayClassName="react_modal_overlay"
      className="react-modal-ErrorOrSucess"
    >
      <div className={styles.container}>
        <XMarkIcon
          onClick={handleToNavigate ? handleToNavigate : onRequestClose}
          className={styles.iconClose}
          title="Fechar"
        />

        <header className={styles.header}>
          <div className={modalClassName}>{icon}</div>

          <strong className={styles.title}>{title}</strong>

          <p>{description}</p>
        </header>

        {buttons}
      </div>
    </Modal>
  );
}
