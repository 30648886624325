import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  patientsCefxAConcluir: <any>[],
};

export const PatientCefxAConcluirSlice = createSlice({
  name: "patientsCefxAConcluir",
  initialState,
  reducers: {
    setpatientCefxAConcluir: (state, action: PayloadAction<any>) => {
      state.patientsCefxAConcluir = action.payload;
      // state.patientsCefxAConcluir = [...state.patientsCefxAConcluir, ...action.payload];
    },
    addPatientCefxAConcluir: (state, action: PayloadAction<any[]>) => {
      if (action.payload.length === 0) {
        state.patientsCefxAConcluir = [];
      } else {
        state.patientsCefxAConcluir = [...action.payload];
      }
    },
    clearPatientCefxAConcluir: (state) => {
      state.patientsCefxAConcluir = [];
    },
  },
});

export const { setpatientCefxAConcluir, addPatientCefxAConcluir, clearPatientCefxAConcluir } = PatientCefxAConcluirSlice.actions;

export const selectPatientsCefxAConcluir = (state: any) => state.patientsCefxAConcluir;

export default PatientCefxAConcluirSlice.reducer;
