import axios, { AxiosInstance } from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY_AUTOMATIC = '8EDB62C4-61C9-4488-BBD2-14203EF99A73';

const api: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
  responseType: "json",
});

const getProtocol = () => {
  return window.location.protocol === 'https:' ? 'https' : 'http';
};

const Api = {

  GetIP: async (clienteId: number): Promise<any> => {
    const req = await api.post(
      `https://cdtsoftware.com.br/cli/p/so.ashx?so=${Number(
        clienteId
      )}&geturl=R`
    );
    return req.data;
  },

  SignIn: async (payload: any): Promise<any> => {
    const req = await api.post(`/api/authentication/authenticate`, payload);

    return req.data;
  },

  SignInAutomatic: async (token: string): Promise<any> => {

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token}`,
      ...(token && { "api-key": API_KEY_AUTOMATIC }),
    }

    const req = await api.post(`/api/authentication/automatic-authentication`, {}, { headers });
    return req.data;
  },


  RecoverPassword: async (payload: any): Promise<any> => {
    const req = await api.post(`/api/authentication/recover-password`, payload);
    return req.data;
  },

  ChangePassword: async (payload: any, token: string): Promise<any> => {
    const { password } = payload;

    const headers = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      authorization: `Bearer ${token}`,
    };

    const req = await api.post(
      `/api/authentication/change-password`,
      `"${password}"`,
      { headers }
    );

    return req.data;
  },

  GetPatientList: async (
    payload?: any,
    searchName?: string,
    signal?: AbortSignal
  ): Promise<any> => {
    let headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    };

    if (searchName) {
      headers.searchName = searchName;
    }

    const req = await api.post(`/api/patient/GetPatients`, payload, {
      headers,
      signal,
    });
    return req.data;
  },

  GetPatientListCefx: async (payload?: any, parametersCefx?: any, options?: any): Promise<any> => {
    let headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    };

    if (parametersCefx) {
      headers.process_id = parametersCefx.process_id;
      headers.to_conclude = parametersCefx.to_conclude;
    }

    if (options && options.searchName) {
      headers.searchName = options.searchName;
    }

    const config = {
      headers,
      ...(options?.signal ? { signal: options.signal } : {}),
    };

    const req = await api.post(`/api/patient/GetPatientsCefx`, payload, config);

    return req.data;
  },

  GetAparelhosCefx: async (): Promise<any> => {
    const req = await axios.post(
      `https://cefx.cdtsoftware.com.br/api/web/aparelho/listar`
    )
    return req.data;
  },

  GetProcess: async (idCliente: any): Promise<any> => {
    const req = await api.get(`/api/patient/GetProcess/${idCliente}`);
    return req.data;
  },

  GetPublicarNoServerodonto: async (idClient: number, atd: number): Promise<any> => {
    const req = await api.get(`/api/patient/PublicarNoServerodonto/${idClient}/${atd}`);
    return req.data;
  },

  GetImagensPatient: async (idCliente: string, idPatient: number, seqAtend: number): Promise<any> => {
    const req = await api.get(`/api/patient/GetImagesPatient/${idCliente}/${idPatient}/${seqAtend}`);
    return req.data;
  },

  GetImagensPatientEvolcloud: async (hash: string, clinica: number, seqAtend: number, uid: string) => {
    const req = await api.get(`/api/evolcloud/listar-imagens-paciente/${hash}/${clinica}/${seqAtend}/${uid}`);
    return req.data;
  },

  DownloadFilePatient: async (idCliente: string, seqAtend: number, complemento: string) => {
    const protocolo = getProtocol();
    const req = await api.get(`/api/patient/DownloadFilePatient/${idCliente}/${seqAtend}/${complemento}`, {
      responseType: 'blob',
      // headers: {
      //   'protocolo': protocolo
      // }
    });
    return req.data
  },

  CreateUser: async (payload: any): Promise<any> => {
    const req = await api.post(`/api/user/create`, payload);
    return req.data;
  },

  GetuserById: async (id: number): Promise<any> => {
    const req = await api.get(`/api/user/${id}`);
    return req.data;
  },

  EditUser: async (props: any): Promise<any> => {
    const { id, payload } = props;

    const req = await api.put(`/api/user/${id}`, payload);
    return req.data;
  },

  VerifyIfCpfExists: async (props: any): Promise<any> => {
    const { cpf } = props;
    const params = { cpf };
    const req = await api.get(`/api/user/VerifyIfCpfExists`, { params });
    return req.data;
  },

  VerifyLoginExists: async (props: any): Promise<any> => {
    const { login } = props;
    const params = { login };
    const req = await api.get(`/api/user/VerifyILoginExists`, { params });
    return req.data;
  },

  deleteClinics: async (id: any): Promise<any> => {
    const req = await api.put(`/api/user_clinica/delete/${id}`);
    return req.data;
  },

  GetUserDetails: async (props: any): Promise<any> => {
    const { idClient, hash } = props;
    const params = { idClient, hash };
    const req = await api.get(`/api/user/getDetails`, { params });
    return req.data;
  },

  getClinics: async (userId: any): Promise<any> => {
    const req = await api.get(`/api/user_clinica/byUserId?userId=${userId}`);
    return req.data;
  },

  // GetUserClinicaByUserId: async (props: any): Promise<any> => {
  //   const { userId } = props;
  //   const params = { userId };
  //   const req = await api.get(`/api/user_clinica/byUserId`, { params });
  //   return req.data;
  // },

  GetUserClinicaByUserId: async (props, options = {}) => {
    const { userId } = props;
    const params = { userId };

    const req = await api.get(`/api/user_clinica/byUserId`, { params, ...options });

    return req.data;
  },

  searchClinics: async (text: string): Promise<any> => {
    const req = await api.get(`/api/user_clinica/getClinicas?text=${text}`);
    return req.data;
  },

  postClinic: async (clinics: any): Promise<any> => {
    const req = await api.post(`/api/user_clinica/create`, clinics);
    return req.data;
  },

  getIndicator: async (id: any): Promise<any> => {
    const req = await api.get(`/api/indicator/ ${id}`);
    return req.data;
  },
  getAllCourseCategories: async (): Promise<any> => {
    const req = await api.get(`/api/course_category`);
    return req.data;
  },

  getAllTeachers: async (): Promise<any> => {
    const req = await api.get(`/api/teacher`);
    return req.data;
  },

  postCourse: async (course: any): Promise<any> => {
    const req = await api.post(`/api/course/create`, course);
    return req.data;
  },

  getCourseById: async (id: any): Promise<any> => {
    const req = await api.get(`/api/course/${id}`);
    return req.data;
  },

  getAllOccupationAreas: async (): Promise<any> => {
    const req = await api.get(`/api/occupation_area`);
    return req.data;
  },

  postTeacher: async (teacher): Promise<any> => {
    const req = await api.post(`/api/teacher/create`, teacher);
    return req.data;
  },

  postLesson: async (lesson): Promise<any> => {
    const req = await api.post(`/api/lesson/create`, lesson);
    return req.data;
  },

  getLessonFromCourse: async (id): Promise<any> => {
    const req = await api.get(`/api/lesson/get-bycourse/${id}`);
    return req.data;
  },

  getAllCourse: async (): Promise<any> => {
    const req = await api.get(`/api/course/get-all`);

    return req.data;
  },

  getLessons: async (id: any): Promise<any> => {
    const req = await api.get(`/api/lesson/get-bycourse/${id}`);

    return req.data;
  },

  getCategoryId: async (id: any): Promise<any> => {
    const req = await api.get(`/api/course_category/${id}`);

    return req.data;
  },

  getAllCategory: async (): Promise<any> => {
    const req = await api.get(`/api/course_category`);

    return req.data;
  },

  getTeacherById: async (id): Promise<any> => {
    const req = await api.get(`/api/teacher/${id}`);

    return req.data;
  },

  getAllIndicators: async (id): Promise<any> => {
    const req = await api.post(`/api/patient/GetIndicators?IdClient=${id}`);

    return req.data;
  },

  postIndicators: async (teacher): Promise<any> => {
    const req = await api.post(`/api/indicator/create`, teacher);
    return req.data;
  },

  GetServices: async (idClient): Promise<any> => {
    const req = await api.post(`/api/patient/GetServices?IdClient=${idClient}`);
    return req.data;
  },

  createShared: async (shared): Promise<any> => {
    const req = await api.post(`/api/shared/create`, shared);
    return req.data;
  },

  GetShared: async (Shared): Promise<any> => {
    const req = await api.get(`/api/shared?idPatient=${Shared}`);
    return req.data;
  },

  byemail: async (Shared): Promise<any> => {
    const req = await api.get(`/api/user/byemail?logon=${Shared}`);
    return req.data;
  },

  UpdateShared: async (id, teacher): Promise<any> => {
    const req = await api.put(`/api/shared/${id}`, teacher);
    return req.data;
  },

  GetPatientByUniqueLink: async (course: any): Promise<any> => {
    let headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    };

    if (course) {
      headers.uniqueLink = course;
    }

    const req = await api.get(`/api/patient/GetPatientByUniqueLink`, {
      headers,
    });
    return req.data;
  },

  getnotificationById: async (id): Promise<any> => {
    const req = await api.get(`/api/notification_user/get-by-user/${id}`);

    return req.data;
  },

  updateNotificationById: async (id, info): Promise<any> => {
    console.log(id, info);
    const req = await api.put(
      `/api/notification_user/read-notification/${id}`,
      info
    );

    return req.data;
  },

  GetAllTypeExamRequestPart: async (token: string): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "api-key": `${token}`,
    };

    const req = await api.get("/api/pwa/type-exam-request-part/all", {
      headers,
    });
    return req.data;
  },

  GetTypeExamRequestPart: async (id: number, token: string): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "api-key": `${token}`,
    };

    const req = await api.get(`/api/pwa/type-exam-request-part/${id}`, {
      headers,
    });
    return req.data;
  },

  PostTypeExamRequestPart: async (data: any, token: string): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "api-key": `${token}`,
    };

    const req = await api.post(`/api/pwa/type-exam-request-part`, data, {
      headers,
    });
    return req.data;
  },

  UpdateTypeExamRequestPart: async (
    id: number,
    token: string
  ): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "api-key": `${token}`,
    };

    const req = await api.put(`/api/pwa/type-exam-request-part/${id}`, {
      headers,
    });
    return req.data;
  },

  GetAllExamRequestPart: async (
    userId: number,
    token: string
  ): Promise<any> => {
    const req = await api.get(`api/pwa/exam-request-part/user/${userId}`);
    return req.data;
  },

  GetExamRequestPart: async (id: number): Promise<any> => {
    const req = await api.get(`api/pwa/exam-request-part/${id}`);
    return req.data;
  },

  PostExamRequestPart: async (data: any, token: string): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "api-key": `${token}`,
    };

    const req = await api.post(`api/pwa/exam-request-part`, data, { headers });
    return req.data;
  },

  UpdatePostExamRequestPart: async (
    id: number,
    token: string
  ): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "api-key": `${token}`,
    };

    const req = await api.put(`api/pwa/exam-request-part${id}`, { headers });
    return req.data;
  },

  GetAllExamRequestModel: async (userId: number): Promise<any> => {
    const req = await api.get(`api/pwa/exam-request-model/user/${userId}`);
    return req.data;
  },

  GetExamRequestModel: async (id: number): Promise<any> => {
    const req = await api.get(`api/pwa/exam-request-model/${id}`);
    return req.data;
  },

  PostExamRequestModel: async (modelData: any): Promise<any> => {
    const req = await api.post(`api/pwa/exam-request-model`, modelData);
    return req.data;
  },

  PutExamRequestModel: async (id: number, data: any): Promise<any> => {
    const req = await api.put(`api/pwa/exam-request-model/${id}`, data);
    return req.data;
  },

  PostExamRequestSend: async (
    data: any,
    token: string,
    hash: string
  ): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      hash: `${hash}`,
      "api-key": `${token}`,
    };

    const req = await api.post(`api/pwa/exam-request/send`, data, { headers });
    return req.data;
  },

  PostExamRequestCreate: async (
    data: any,
    token: string,
    hash: string
  ): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      hash: `${hash}`,
      "api-key": `${token}`,
    };

    const req = await api.post(`api/pwa/exam-request/create`, data, {
      headers,
    });
    return req.data;
  },

  GetAllExamRequest: async (
    userId: number,
    token: string,
    hash: string
  ): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      hash: `${hash}`,
      "api-key": `${token}`,
    };

    const req = await api.get(`api/pwa/exam-request/user/${userId}`, {
      headers,
    });
    return req.data;
  },

  GetExamRequest: async (
    id: number,
    token: string,
    hash: string
  ): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      hash: `${hash}`,
      "api-key": `${token}`,
    };

    const req = await api.get(`api/pwa/exam-request/${id}`, { headers });
    return req.data;
  },

  UpdateExamRequest: async (
    id: number,
    data: any,
    token: string,
    hash: string
  ): Promise<any> => {
    const headers: any = {
      accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      hash: `${hash}`,
      "api-key": `${token}`,
    };

    const req = await api.put(`api/pwa/exam-request/${id}`, data, { headers });
    return req.data;
  },
};

export default Api;
