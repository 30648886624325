import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

import { HeaderViewImageComponent } from "./HeaderViewImageComponent";
import { is3D } from "../../../helpers/Funcoes";
import { Dicom } from "./Dicom";
import { selectUser } from "../../../store/slices/userSlice";
import { isDicomTypeUtils } from "../../../utils/fileTypes/isDicomTypeUtils";
import { is3dTypeUtils } from "../../../utils/fileTypes/is3dTypeUtils";

import styles from "./styles.module.css";
import { useOpcoes } from "../NewSideberCefx/OpcoesContext";

interface ViewImageComponentProps {
  pacienteAllImagensArray?: any;
  pacientAllImagens3d?: any;
  handleModoCefX: () => void;
  imageParams?: string;
  tamanhoParams?: number;
  selectedImage: any[];
  valueImageRotate?: number;
  theSelectImage?: string;
  oViewer?: any;
  ocefx?: any;
  viwerActives?: any;
  selectedImages?: any;
  getCollapse?: boolean;
}

export function ViewImageComponent({
  handleModoCefX,
  imageParams,
  tamanhoParams,
  selectedImage,
  theSelectImage,
  valueImageRotate,
  pacienteAllImagensArray,
  pacientAllImagens3d,
  oViewer,
  ocefx,
  viwerActives,
  selectedImages,
  getCollapse
}: ViewImageComponentProps) {
  const { brightness, contrast } = useOpcoes();

  const location = useLocation();
  const { user } = useSelector(selectUser)

  const queryString = location.search.substring(1);
  const queryParams = new URLSearchParams(queryString);

  const idClienteParams = queryParams.get("id");
  const seqAtendParams = queryParams.get("atd");
  const idPacienteParams = queryParams.get("idPacient");

  const [image3d, setImage3d] = useState("");
  const [image3dTrue, setImage3dTrue] = useState(false);
  const [viewerKey, setViewerKey] = useState(0);
  const [selected, setSelected] = useState(theSelectImage);
  const [name, setName] = useState();
  const [selected3d, setSelected3d] = useState(theSelectImage);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [selectedImageIndex3d, setSelectedImageIndex3d] = useState(-1);
  const [arrowKeyPressed, setArrowKeyPressed] = useState(null);
  const [checkedImage, setCheckedImage] = useState(selectedImages);

  const canvasRef = useRef(null);

  const handleNextImage = () => {
    if (
      setCheckedImage(""),
      selectedImageIndex !== -1 &&
      selectedImageIndex < pacienteAllImagensArray.length - 1
    ) {
      setCheckedImage("")
      const nextImageIndex = selectedImageIndex + 1;
      setSelectedImageIndex(nextImageIndex);
      const nextImage = pacienteAllImagensArray[nextImageIndex].link;
      setName(pacienteAllImagensArray[nextImageIndex].nome);
      setSelected(nextImage);
    }
  };

  const handleNextImage3d = () => {
    if (!theSelectImage) {
      if (
        is3dTypeUtils(imageParams) ||
        isDicomTypeUtils(imageParams)
      ) {
        if (
          selectedImageIndex3d !== -1 &&
          selectedImageIndex3d < pacientAllImagens3d?.length - 1
        ) {
          const nextImageIndex = selectedImageIndex3d + 1;
          setSelectedImageIndex3d(nextImageIndex);
          const nextImage = pacientAllImagens3d[nextImageIndex].link;
          setSelected3d(nextImage);
          setImage3dTrue(false);
        }
      } else {
        handleNextImage();
      }
    } else {
      if (
        is3dTypeUtils(theSelectImage) ||
        isDicomTypeUtils(theSelectImage)
      ) {
        if (
          selectedImageIndex3d !== -1 &&
          selectedImageIndex3d < pacientAllImagens3d?.length - 1
        ) {
          const nextImageIndex = selectedImageIndex3d + 1;
          setSelectedImageIndex3d(nextImageIndex);
          const nextImage = pacientAllImagens3d[nextImageIndex].link;
          setSelected3d(nextImage);
          setImage3dTrue(false);
        }
      } else {
        handleNextImage();
      }
    }
  };

  const handlePreviousImage = () => {
    if (selectedImageIndex > 0) {
      const previousImageIndex = selectedImageIndex - 1;
      setSelectedImageIndex(previousImageIndex);
      const previousImage = pacienteAllImagensArray[previousImageIndex].link;
      setName(pacienteAllImagensArray[previousImageIndex].nome);
      setSelected(previousImage);
    }
  };

  const handlePreviousImage3d = () => {
    if (
      is3dTypeUtils(theSelectImage) ||
      isDicomTypeUtils(theSelectImage)
    ) {
      if (selectedImageIndex3d > 0) {
        const previousImageIndex = selectedImageIndex3d - 1;
        setSelectedImageIndex3d(previousImageIndex);
        const previousImage = pacientAllImagens3d[previousImageIndex].link;
        setSelected3d(previousImage);
      }
    } else {
      handlePreviousImage();
    }
  };

  useEffect(() => {
    if (!theSelectImage) {
      const index = pacienteAllImagensArray?.findIndex(
        (image: any) => image.link === imageParams
      );

      const index3d = pacientAllImagens3d?.findIndex(
        (image: any) => image.link === imageParams
      );

      const imageInfo = pacienteAllImagensArray[index];
      if (imageInfo) {
        const nomeDoImage = imageInfo.nome;
        const LinkdaImage = imageInfo.link;

        setName(nomeDoImage);
        setSelected(LinkdaImage);
        setSelectedImageIndex(index);
        setSelectedImageIndex3d(index3d);
      }
    } else {
      const index = pacienteAllImagensArray.findIndex(
        (image: any) => image.link === theSelectImage
      );

      const index3d = pacientAllImagens3d?.findIndex(
        (image: any) => image.link === theSelectImage
      );

      const imageInfo = pacienteAllImagensArray[index];
      if (imageInfo) {
        const nomeDoImage = imageInfo.nome;
        const LinkdaImage = imageInfo.link;
        setName(nomeDoImage);
        setSelected(LinkdaImage);
        setSelectedImageIndex(index);
        setSelectedImageIndex3d(index3d);
      }
    }
  }, [
    theSelectImage,
    pacienteAllImagensArray,
    pacientAllImagens3d,
    imageParams,
  ]);


  useEffect(() => {
    if (
      imageParams && (
        is3dTypeUtils(imageParams) ||
        isDicomTypeUtils(imageParams)
      )
    ) {
      let modifiedUrl = imageParams;

      if (imageParams.includes("dicom.zip")) {
        modifiedUrl = imageParams.replace("dicom.zip", "dcm.zip");
      }

      // if (imageParams.includes("http") && imageParams.includes(".zip")) {
      //   const regex = /^(.*?\.dfx)/;
      //   const match = imageParams.match(regex);

      //   console.log(match)

      //   if (match && match.length > 0) {
      //     modifiedUrl = match[0];
      //   }
      // }

      // modifiedUrl = modifiedUrl;
      setImage3d(modifiedUrl);
      setImage3dTrue(true);
    } else {
      setImage3dTrue(false);
    }

    if (theSelectImage) {
      setImage3dTrue(false);
    }
  }, [imageParams, theSelectImage]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      ocefx.setCanvas(canvas);
      ocefx.setModoCefx(false);
      ocefx.setImagem(selected || imageParams);

      canvasRef.current.style.setProperty(
        "transform",
        selectedImage.length === 0 && `rotate(${valueImageRotate}deg)`,
        "important"
      );
    }
  }, [canvasRef, theSelectImage, selectedImageIndex, pacienteAllImagensArray]);

  useEffect(() => {
    setViewerKey((prevKey) => prevKey + 1);
  }, [theSelectImage, selected3d]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "ArrowLeft") {
        setArrowKeyPressed("left");
      } else if (e.key === "ArrowRight") {
        setArrowKeyPressed("right");
      }
    };

    const handleKeyUp = () => {
      setArrowKeyPressed(null);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (arrowKeyPressed === "left") {
      handlePreviousImage();
    } else if (arrowKeyPressed === "right") {
      handleNextImage();
    }
  }, [arrowKeyPressed]);

  /* ----------- 3d ---------- */
  const initialized = useRef(false);
  const prevSelected3d = useRef(false);
  const [first3dImage, setFirst3dImage] = useState(false);

  const initializeLoadingStyles = () => {
    const spnLoading = document.getElementById("spnLoading");
    spnLoading.style.display = "flex";
    spnLoading.style.opacity = "1";
    spnLoading.style.position = "absolute";
    spnLoading.style.left = "50%";
    spnLoading.style.transform = "translateX(-50%)";
  };

  useEffect(() => {
    const divContainer3d = document.getElementById("divContainer3d");
    const container3d = document.getElementById("container3d");
    if (divContainer3d) {
      if (!is3dTypeUtils(theSelectImage) && theSelectImage) {
        divContainer3d.style.height = "0";
        divContainer3d.style.display = "none";
        divContainer3d.style.background = "transparent";

        container3d.style.display = "none";
        container3d.style.height = "0";
      } else if (!initialized.current && image3d) {
        initialized.current = true;
        initializeLoadingStyles();
        oViewer.inicializar();
        if (first3dImage && selected3d !== "") {
          let fileSize = tamanhoParams;
          oViewer.abrir(selected3d, fileSize, idClienteParams);
        } else {
          let fileSize = tamanhoParams;

          if (window.location.protocol == 'http:') {
            let baseDomain = 'https://serapi.cdt.com.br';
            let newUrl = image3d;
            const fileFormat = image3d.split('/').pop();

            if (image3d.startsWith('https://cdt2so.blob.core.windows.net')) {
              oViewer.abrir(image3d, fileSize, idClienteParams);
            } else {
              const userData = user.find((u: any) => u.idClient === idClienteParams);

              if (userData && userData.ip !== '') {
                baseDomain = userData.ip;
                newUrl = `${baseDomain}/p/getfile.aspx?atd=${seqAtendParams}&cmp=${fileFormat}`;
              } else {
                newUrl = image3d;
              }

              oViewer.abrir(newUrl, fileSize, idClienteParams);
            }
          } else {
            oViewer.abrir(image3d, fileSize, idClienteParams);
          }
          //oViewer.abrir('http://186.236.72.106:8089/p/getFile.aspx?atd=600&cmp=dcm.zip');
        }
      } else if (is3dTypeUtils(theSelectImage) && !first3dImage) {
        divContainer3d.style.display = "block";
        divContainer3d.style.height = "100%";
        divContainer3d.style.background = "#000";

        container3d.style.display = "block";
        container3d.style.height = "calc(100vh - 108px)";
      }

      if (
        is3dTypeUtils(theSelectImage) &&
        !is3dTypeUtils(imageParams) &&
        !initialized.current &&
        !prevSelected3d.current
      ) {
        initialized.current = true;
        prevSelected3d.current = true;

        divContainer3d.style.display = "block";
        divContainer3d.style.height = "100%";
        divContainer3d.style.background = "#000";

        initializeLoadingStyles();
        oViewer.inicializar();
      }
    }
  }, [
    image3d,
    imageParams,
    selected3d,
    first3dImage,
    selected,
    theSelectImage,
  ]);

  return (
    <div>
      <HeaderViewImageComponent
        handleModoCefX={handleModoCefX}
        image3dParams={image3dTrue ? image3d : undefined}
        imageParams={theSelectImage ? theSelectImage : imageParams}
        name={name}
      />

      <div
        className={`${styles.containerSelectedImagens} ${selectedImage.length === 0 ? styles.singleImage : ""
          }`}
        style={{
          filter: `brightness(${brightness}%) contrast(${contrast}%)`,
        }}
      >
        < button
          type="button"
          onClick={handlePreviousImage3d}
          className={styles.containerButtonleft}
          style={{ display: theSelectImage.endsWith(".dfx") || imageParams.endsWith(".dfx") || imageParams.includes(".dfx") || imageParams.includes(".zip") ? 'none' : 'block' }}
        >
          <CaretLeft size={28} />
        </button>

        <div className={styles.containerCanvas}>
          {!canvasRef ? (
            <div className={styles.container3d}>
              <div
                style={{
                  height: "calc(100vh - 108px)",
                }}
                id="divContainer3d"
              >
                <span
                  id="spnLoading"
                  style={{
                    display: "none",
                    opacity: "0",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#d3d3d3",
                    fontSize: "0.9rem",
                    textAlign: 'center'
                  }}
                >
                  Carregando... isso pode demorar alguns instantes.
                </span>
              </div>
            </div>

          ) : !canvasRef ||
            isDicomTypeUtils(theSelectImage) ||
            isDicomTypeUtils(imageParams) ? (
            <Dicom
              viwerActives={viwerActives}
              oViewer={oViewer}
            />
          ) : (
            <canvas
              className={styles.canvasteste}
              ref={canvasRef}
              style={{
                cursor: "grab",
                display: "block",
                position: "relative",
                transform: selectedImage.length === 0 && `rotate(${valueImageRotate}deg)`,
                width: "100%",
                height: "calc(100vh - 125px)",
                left: getCollapse ? '0' : '-150px',
                top:
                  Math.abs(valueImageRotate) === 0
                    ? "0%"
                    : Math.abs(valueImageRotate) === 80
                      ? "-11%"
                      : Math.abs(valueImageRotate) === 90
                        ? "-18%"
                        : "0",
              }}
            />
          )}
        </div>

        {!canvasRef || is3D(theSelectImage || imageParams || imageParams.endsWith) ? (
          <div className={styles.container3d} id="container3d">
            <div
              style={{
                height: "calc(100vh - 108px)",
              }}
              id="divContainer3d"
            >
              <span
                id="spnLoading"
                style={{
                  display: "none",
                  opacity: "0",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#d3d3d3",
                  fontSize: "0.9rem",
                  textAlign: 'center'
                }}
              >
                Carregando... isso pode demorar alguns instantes.
              </span>
            </div>
          </div>
        ) : (
          <div id="container3d">
            <div
              style={{
                height: "0",
                display: "none",
              }}
              id="divContainer3d"
            >
              <span
                id="spnLoading"
                style={{
                  display: "none",
                  opacity: "0",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#d3d3d3",
                  fontSize: "0.9rem",
                  textAlign: 'center'
                }}
              >
                Carregando... isso pode demorar alguns instantes.
              </span>
            </div>
          </div>
        )}

        <button
          type="button"
          className={styles.containerButtonRight}
          onClick={handleNextImage3d}
          style={
            {
              display: theSelectImage.endsWith(".dfx") || imageParams.endsWith(".dfx") || imageParams.includes(".dfx") || imageParams.includes(".zip") ? 'none' : 'block',
              right: getCollapse && '10px'
            }
          }
        >
          <CaretRight size={28} />
        </button>
      </div>
    </div >
  );
}
