import { useEffect, useState } from "react";

import {
  PhotoIcon,
  DocumentTextIcon,
  StarIcon,
  ShareIcon,
} from "@heroicons/react/24/solid";
import { Tooth } from "@phosphor-icons/react";

import { Imagens } from "../Pages/Imagens";
import { Documents } from "../Pages/Documents";
import { Assessments } from "../Pages/Assessments";
import { Exams } from "../Pages/Exams";
import { Share } from "../Pages/Share";

import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.module.css";
import { info } from "console";
import Api from "../../../helpers/Api";
import { selectShared } from "../../../store/slices/sharedSlice";
import { useLocation } from "react-router-dom";


export function Navigation({ infoPacient, loadingImagens, isShare }: any) {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 684px)" });
  const [activeTab, setActiveTab] = useState("imagens");
  const [objShare, SetObjShared] = useState([]);
  const [profileSuccessfully, setProfileSuccessfully] = useState(false);

  // const imagensPacienteFiltradasMobile = isMobileScreen
  //   ? infoPacient.imagensPaciente.filter((imagem) =>
  //       !imagem.complemento.endsWith(".zip") &&
  //       !imagem.complemento.endsWith(".dfx")
  //   )
  //   : infoPacient.imagensPaciente;

  const newObjectWithPatientNameAndId = {
    Paciente: infoPacient.paciente,
    Id: infoPacient.id,
  };


  const newObjectDataShare = {
    Indicacao: infoPacient.indicacao,
    Email: infoPacient.email,
  };

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const filtrarImagensAnalise = () => {
    const imagensAnalise = infoPacient.imagensPaciente?.filter((imagem) => {
      return (
        imagem.link.endsWith(".pdf") ||
        imagem.complemento.endsWith(".rar") ||
        imagem.complemento.endsWith(".zip") ||
        imagem.complemento.endsWith(".cfx")
      );
    });

    return imagensAnalise;
  };

  const DocumentsFiltrados = filtrarImagensAnalise();

  const extractDate = (dataAtend: string): string | null => {
    const regex = /^(\d{4}-\d{2}-\d{2})/;
    const match = dataAtend?.match(regex);

    if (match && match[1]) {
      const [year, month, day] = match[1].split("-");
      return `${day}/${month}/${year}`;
    }

    return null;
  };

  const getProfileSharedSuccessfully = (successfully: boolean) => {
    setProfileSuccessfully(successfully)
  }

  const getShared = async () => {
    try {
      const shared = await Api.GetShared(infoPacient.seqAtend);
      if (shared.isSuccess) {
        SetObjShared(shared.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getShared();
  }, [profileSuccessfully]);

  var url = window.location.href;

  const renderContent = () => {
    switch (activeTab) {
      case "imagens":
        return (
          <Imagens
            PatientNameAndId={newObjectWithPatientNameAndId}
            imagensPaciente={infoPacient.imagensPaciente}
            dataAtendimento={extractDate(infoPacient.dataAtend)}
            imagemPerfil={infoPacient.imagem}
            loadingImagens={loadingImagens}
            isShare={isShare && infoPacient}
          />
        );
      case "documentos":
        return (
          <Documents
            PatientNameAndId={newObjectWithPatientNameAndId}
            documentsPaciente={DocumentsFiltrados}
            imagemPerfil={infoPacient.imagem}
          />
        );
      case "exames":
        return <Exams examesPaciente={infoPacient.exames} />;
      case "avaliacoes":
        return <Assessments assessmentsPaciente={infoPacient.avaliacoes} />;
      case "compartilhamento":
        return (
          <Share
            newObjectDataShare={objShare}
            infoPacient={infoPacient}
            getShared={getShared}
            getProfileSharedSuccessfully={getProfileSharedSuccessfully}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className={styles.wrapper}>
      <nav>
        <ul>
          <li
            onClick={() => handleTabClick("imagens")}
            className={activeTab === "imagens" ? styles.active : ""}
          >
            <span onClick={() => { }}>
              <PhotoIcon />
              Imagens
            </span>
          </li>
          <li
            onClick={() => handleTabClick("documentos")}
            className={activeTab === "documentos" ? styles.active : ""}
          >
            <span>
              <DocumentTextIcon />
              Documentos
            </span>
          </li>
          <li
            onClick={() => handleTabClick("exames")}
            className={activeTab === "exames" ? styles.active : ""}
          >
            <span>
              <Tooth />
              Exames e Serviços
            </span>
          </li>
          <li
            onClick={() => handleTabClick("avaliacoes")}
            className={activeTab === "avaliacoes" ? styles.active : ""}
          >
            <span>
              <StarIcon />
              Avaliações
            </span>
          </li>

          {url.indexOf("perfil-paciente") !== -1 && <li
            onClick={() => handleTabClick("compartilhamento")}
            className={activeTab === "compartilhamento" ? styles.active : ""}
          >
            <span>
              <ShareIcon />
              Compartilhamentos
            </span>
          </li>}

        </ul>
      </nav>

      <div>{renderContent()}</div>
    </div >
  );
}
