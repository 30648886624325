
import styles from './styles.module.css'

export function ErrorFallback() {

    const closeViwer = () => {
        window.history.back();
    };

    return (
        <div className={styles.containerError}>
            <div className={styles.containerImage}>
                <img src="/logo_serodonto_verde.png" width={"200px"} />
            </div>

            <h1>
                Oops! Algo deu errado.
            </h1>
            <p>
                Por favor, recarregue a página ou tente novamente mais tarde.
            </p>

            <button
                onClick={() => closeViwer()}
            >
                Voltar
            </button>
        </div>
    )
}