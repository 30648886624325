import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import { Toaster } from "react-hot-toast";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./index.css";

const root = document.getElementById("root");

const client = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <PersistGate persistor={persistor} loading={null}>
      <QueryClientProvider client={client}>
        <Toaster position="top-right" />
        <App />
      </QueryClientProvider>
    </PersistGate>
  </React.StrictMode>,
  root
);
