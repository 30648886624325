import { useRef, useEffect } from "react";
import "@kitware/vtk.js/Rendering/Profiles/Volume";
import { toast } from "react-hot-toast";

import vtkOpenGLRenderWindow from "@kitware/vtk.js/Rendering/OpenGL/RenderWindow";
import vtkRenderWindow from "@kitware/vtk.js/Rendering/Core/RenderWindow";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import vtkInteractorStyleTrackballCamera from "@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera";
import vtkRenderWindowInteractor from "@kitware/vtk.js/Rendering/Core/RenderWindowInteractor";

import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkSTLReader from "@kitware/vtk.js/IO/Geometry/STLReader";

import vtkMTLReader from "@kitware/vtk.js/IO/Misc/MTLReader";
import vtkOBJReader from "@kitware/vtk.js/IO/Misc/OBJReader";
import vtkTexture from "@kitware/vtk.js/Rendering/Core/Texture";

import vtkPLYReader from "@kitware/vtk.js/IO/Geometry/PLYReader";

import vtkXMLImageDataReader from "@kitware/vtk.js/IO/XML/XMLImageDataReader";

import readImageDICOMFileSeries from "itk/readImageDICOMFileSeries";
import readImageFile from "itk/readImageFile";

import itkConfig from "itk/itkConfig";
import ITKHelper from "@kitware/vtk.js/Common/DataModel/ITKHelper";

import ImageConstants from "@kitware/vtk.js/Rendering/Core/ImageMapper/Constants";

import abreMPRNova, { criarMPR } from "./navegacao.js";
import updateViews1 from "./navegacao.js";

import $ from "jquery";
import JSZip from 'jszip';

const { SlicingMode } = ImageConstants;

class CViewer3d {

  //-------------Construtor
  constructor() {
    this.renderWindow = vtkRenderWindow.newInstance();
    this.renderer = vtkRenderer.newInstance({ background: [0, 0, 0] });
    this.atores = [];
    this.coords = { x: 0, y: 0, bt: 0 };
  } // fim do construtor

  inicializar() {
    // if(typeof(this.renderer) === 'undefined') {
    //   return
    // }

    this.pintar = "";
    this.cliente = "0";

    this.renderWindow.addRenderer(this.renderer);

    //const resetCamera = renderer.resetCamera;
    //const render = renderWindow.render;

    const openGLRenderWindow = vtkOpenGLRenderWindow.newInstance();
    this.renderWindow.addView(openGLRenderWindow);

    // ----------------------------------------------------------------------------
    // Create a div section to put this into
    // ----------------------------------------------------------------------------

    const container = document.getElementById("divContainer3d");
    //document.querySelector('body').appendChild(container);
    openGLRenderWindow.setContainer(container);

    const { width, height } = container.getBoundingClientRect();
    openGLRenderWindow.setSize(width, height);

    const interactor = vtkRenderWindowInteractor.newInstance();
    interactor.setView(openGLRenderWindow);
    interactor.initialize();
    interactor.bindEvents(container);
    interactor.setInteractorStyle(
      vtkInteractorStyleTrackballCamera.newInstance()
    );
  }

  abrirSemCliente(url) {
    this.abrir(url, "0");
  }

  abrir(url, fileSize, idcliente) {
    document.getElementById("spnLoading").style.display = "block";
    sessionStorage.setItem('progressLoadedDicom', '0')
    sessionStorage.setItem("dicom", 'iniciando');

    var novaURL = url;
    this.cliente = idcliente;

    var oator = this.atores.find((x) => x.url === novaURL);


    if (oator != null) {
      document.getElementById("spnLoading").style.display = "none";
      var tog = oator.actor.getVisibility();
      oator.actor.setVisibility(!tog);
      this.renderWindow.render();
      return;
    }

    if (novaURL.endsWith("stl")) {
      this.abreSTL(novaURL)
    } else if (novaURL.endsWith("obj")) {
      this.abreOBJ(novaURL);
    } else if (novaURL.endsWith("ply")) {
      this.abrePly(novaURL);
    } else if (novaURL.endsWith("dcdt")) {
      this.abreDCDT(novaURL);
      var ator = { url: novaURL, actor: null };
      this.atores.push(ator);
    } else if (novaURL.endsWith("dfx")) {
      this.abreDFX(novaURL, fileSize);
      var ator = { url: novaURL, actor: null };
      this.atores.push(ator);
      if (!novaURL.includes('https://cdt2so.blob.core.windows.net')) {
        setTimeout(() => {
          sessionStorage.setItem("dicom", 'abrindo');
        }, 2500);
      }
    } else if (novaURL.endsWith("zip")) {
      this.abrezip(novaURL, fileSize);
      var ator = { url: novaURL, actor: null };
      this.atores.push(ator);
      if (!novaURL.includes('https://cdt2so.blob.core.windows.net')) {
        setTimeout(() => {
          sessionStorage.setItem("dicom", 'abrindo');
        }, 2500);
      }
    }

    return "";
  }

  abreSTL(novaURL) {
    const reader = vtkSTLReader.newInstance();
    reader
      .setUrl(novaURL)
      .then(() => {
        document.getElementById("spnLoading").style.display = "none";
        const polydata = reader.getOutputData(0);
        const mapper = vtkMapper.newInstance({ scalarVisibility: false });
        const actor = vtkActor.newInstance();

        actor.setMapper(mapper);
        mapper.setInputData(polydata);
        actor.getProperty().setColor(1.0, 1.0, 1.0);

        this.renderer.addActor(actor);

        this.renderer.resetCamera();
        this.renderWindow.render();

        var ator = { url: novaURL, actor: actor };
        this.atores.push(ator);

        this.pintar = novaURL;
      })
      .catch((error) => {
        //nao funciona, nao da o erro
        //alert(error);
        // document.getElementById("spnLoading").innerHTML = "errl";
      });
  } //stl

  abreOBJ(novaURL) {
    const readero = vtkOBJReader.newInstance({ splitMode: "usemtl" });
    const materialsReader = vtkMTLReader.newInstance();
    const scene = [];
    var foto = novaURL.substring(0, novaURL.lastIndexOf(".")) + ".jpg";
    // console.log(foto);
    $.ajax({
      url: foto,
      xhrFields: { responseType: "blob" },
      success: function (data) {
        const img = new Image();
        var blobData = data;
        var url = window.URL || window.webkitURL;
        var src = url.createObjectURL(data);
        img.src = src;

        readero.setUrl(novaURL).then(() => {
          document.getElementById("spnLoading").style.display = "none";
          const size = readero.getNumberOfOutputPorts();
          for (let i = 0; i < size; i++) {
            const polydata = readero.getOutputData(i);
            const name = polydata.get("name").name;
            const mapper = vtkMapper.newInstance();
            const actor = vtkActor.newInstance();

            actor.setMapper(mapper);
            mapper.setInputData(polydata);

            materialsReader.applyMaterialToActor(name, actor);
            this.renderer.addActor(actor);

            scene.push({ name, polydata, mapper, actor });

            const texture = vtkTexture.newInstance();
            texture.setInterpolate(true);
            texture.setImage(img);
            actor.addTexture(texture);
            var ator = { url: novaURL, actor: actor };
            this.atores.push(ator);
          } //for
          this.renderer.resetCamera();
          this.renderWindow.render();
        }); //carrega arq obj
      }, //carrega textura - sucesso
    }); //carrega textura
  } //obj

  abrePly(novaURL) {
    const readerp = vtkPLYReader.newInstance();
    readerp.setUrl(novaURL).then(() => {
      document.getElementById("spnLoading").style.display = "none";
      const size = readerp.getNumberOfOutputPorts();
      for (let i = 0; i < size; i++) {
        const polydata = readerp.getOutputData(i);
        const name = polydata.get("name").name;
        const mapper = vtkMapper.newInstance();
        const actor = vtkActor.newInstance();

        actor.setMapper(mapper);
        mapper.setInputData(polydata);

        //materialsReader.applyMaterialToActor(name, actor);
        this.renderer.addActor(actor);

        //scene.push({ name, polydata, mapper, actor });

        // const texture =  vtkTexture.newInstance();
        // texture.setInterpolate(true);
        // texture.setImage(img);
        // actor.addTexture(texture);
        var ator = { url: novaURL, actor: actor };
        this.atores.push(ator);
      } //for
      this.renderer.resetCamera();
      this.renderWindow.render();
    }); //carrega arq ply
  } //ply

  abreDCDT(novaURL) {
    document.getElementById("divContainer3d").style.display = 'none';
    document.getElementById("container3d").style.display = 'none';

    const readerv = vtkXMLImageDataReader.newInstance();
    var isto = this;
    readerv
      .setUrl(novaURL, {
        loadData: true,
        progressCallback: (progressEvent) => {
          //loader((100 * progressEvent.loaded) / lstImagens[numAtual].Tamanho / 1024 * 1024);
          console.log(progressEvent.loaded);
        },
      })
      .then(() => {
        isto.abreMPR(readerv.getOutputData(0));
        document.getElementById("spnLoading").style.display = "none";
        sessionStorage.setItem('progressLoadedDicom', '0')
      });
  } //dcdt

  abreMPR(data) {
    criarMPR();
    abreMPRNova(data);
    updateViews1();

  }

  abreDFX(url, fileSize) {
    document.getElementById("divContainer3d").style.display = 'none';
    document.getElementById("container3d").style.display = 'none';

    const readerv2 = vtkXMLImageDataReader.newInstance();
    const isto = this;

    readerv2
      .setUrl(url, {
        loadData: true,
        progressCallback: (progressEvent) => {
          const loaded = progressEvent.loaded;
          const percent = fileSize > 0 ? Math.floor((100 * loaded) / fileSize) : 99;
          sessionStorage.setItem("progressLoadedDicom", percent);
        },

      })
      .then(() => {
        sessionStorage.setItem("dicom", 'Gerando reconstrução');
        setTimeout(() => {
          document.getElementById("spnLoading").style.display = "none";
          sessionStorage.setItem('progressLoadedDicom', '0');
          isto.abreMPR(readerv2.getOutputData());
        }, 4000);
      });
  } //dfx

  abreDicom(files) { //DCM
    sessionStorage.setItem("dicom", 'Gerando reconstrução');
    // o erro � generico, com um sinal de '<' no final da linha
    //quando da erro no itk, tem que copiar arquivos para a pasta public, eles ficam na pasta node_modules/itk

    var isto = this;

    //public/ImageIOS e
    //public/WebWorkers
    itkConfig.itkModulesPath = "/itk";

    var isto = this;

    if (files.length === 1) {
      //nao testei com 1 arq
      readImageFile(null, files[0])
        .then(function ({ image, webWorker }) {
          webWorker.terminate();
          //console.log(image)
        })
        .catch((error) => {
          //console.log(error)
        });
    }
    else {
      readImageDICOMFileSeries(null, files)
        .then(function ({ image, webWorker }) {

          webWorker.terminate();

          //resolveu o problema do dicom estar de costas/invertido

          //if (icat)
          image.direction.data = [1, 0, 0, 0, -1, 0, 0, 0, 1];

          if (isto.cliente + "" == "194")
            image.direction.data = [1, 0, 0, 0, -1, 0, 0, 0, -1];

          const imageData = ITKHelper.convertItkToVtkImage(image);
          // toast("Gerando reconstruçao...", {
          //   position: "top-center",
          //   style: {
          //     background: "#008a00",
          //     color: "#fff",
          //     textAlign: 'center'
          //   },
          // });
          // mensagem - gerando reconstruçao
          isto.abreMPR(imageData);

        })
        .catch((error) => {
          // mensagem de error
          document.getElementById("spnLoading").style.display = 'none';
          document.getElementById("spnLoading").style.opacity = '-1'

          alert('Erro: Dicom ou zip Inválido!\n\nO arquivo precisa ser zip nativo,\ne nao pode ter mais de uma serie/pasta\nÉ importante ter somente arquivos .dcm\n\nverifique junto ao centro de radiologia');
          //console.log(error);
        });
    }
    // document.getElementById("spnLoading").style.display = 'none';
    // document.getElementById("spnLoading").style.opacity = '-1'
  } //DCM;

  unzip = async (zipData) => {
    sessionStorage.setItem("dicom", 'Descompactando');

    const zip = new JSZip();
    const files = [];

    try {
      const zipContents = await zip.loadAsync(zipData);

      const totalFiles = Object.keys(zipContents.files).filter(relativePath => !zipContents.files[relativePath].dir).length;

      let processedFiles = 0;

      for (const relativePath in zipContents.files) {
        const zipEntry = zipContents.files[relativePath];
        if (!zipEntry.dir) {
          if (zipEntry.name.toLowerCase().endsWith(".dcm")) {
            const fileData = await zipEntry.async("uint8array");
            var nome = zipEntry.name;
            if (nome.indexOf('/') >= 0)
              nome = nome.substring(nome.lastIndexOf('/') + 1);
            const file = new File([fileData], nome);
            files.push(file);
          }
          processedFiles++;
          const percent = Math.floor(((processedFiles / totalFiles) * 100).toFixed(2));;
          sessionStorage.setItem("progressLoadedDicom", percent);
        }
      }
    } catch (error) {
      console.error('Erro ao carregar o zip:', error);
    }

    return files;
  };

  abrezip = async (file, fileSize) => {
    ;
    var isto = this;
    $.ajax({
      url: file,
      xhrFields: {
        responseType: "blob",
        onprogress: function (e) {
          const percent = Math.floor(((e.loaded / fileSize) * 100).toFixed(2));;
          sessionStorage.setItem("dicom", 'baixando');
          sessionStorage.setItem("progressLoadedDicom", percent);
          //if (e.lengthComputable) {
          //console.log(e.loaded / e.total * 100 + '%');
          //}
          //else {
          //   console.log(e.loaded + ' baixado');
          //}
        }
      },
      success: async function (data) {
        const files = await isto.unzip(data);
        sessionStorage.setItem("dicom", 'Carregado');
        isto.abreDicom(files);
      },
      error: function (data) {
        console.log('erro ao carregar o arquivo zip:', data);
      },
    });
  };

  //createLinkForEachEntry= (archive) => {
  //    // Get only the entries that are images
  //    let entries = [];
  //    archive.entries.forEach(function (entry) {
  //        if (isValidImageType(entry.name)) {
  //            var nome = entry.name;
  //            await entry.readData(function (data, err) {
  //                if (err) {
  //                    //errorList.innerHTML = err;
  //                    return;
  //                }

  //                // Convert the data into an Object URL
  //                //let blob = new Blob([data], { type: getFileMimeType(entry.name) });
  //                //let url = URL.createObjectURL(blob);

  //                const file = new File([data], nome);
  //                entries.push(file);

  //                //img.src = url;
  //            //    img.onload = function () {
  //            //        URL.revokeObjectURL(url);
  //            //    };
  //            });
  //        }
  //    });
  //}

  //abreRAR = async (file) => {
  //    //let file = fileInput.files[0];
  //    let password = "";// document.getElementById('filePassword').value;

  //    // Open the file as an archive
  //    archiveOpenFile(file, password, function (archive, err) {
  //        if (archive) {
  //           // console.info('Uncompressing ' + archive.archive_type + ' ...');
  //            //entryList.innerHTML = '';
  //            //document.getElementById('currentImage').src = '';
  //            createLinkForEachEntry(archive);
  //        } else {
  //            //entryList.innerHTML = '<span style="color: red">' + err + '</span>';
  //        }
  //    });
  //}

  render() {
    this.renderWindow.render();
  } //render
} //class

export default CViewer3d;
