import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "../src/store/store";
import { PatientProvider } from "./pages/PatientList/PatientProvider";
import { router } from "./routes";
import PWAPrompt from 'react-ios-pwa-prompt'
import ErrorBoundary from "./ErrorBoundary";

import { CheckListProvider } from "./pages/PatientList/CheckListCefx/ChecklistContext";

import Modal from "react-modal";

import "../src/styles/global.css";

Modal.setAppElement("#root");

function App() {
  return (
    <Provider store={store}>
      <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false} />
      <PatientProvider>
        <ErrorBoundary>
          <CheckListProvider>
            <RouterProvider router={router} />
          </CheckListProvider>
        </ErrorBoundary>
      </PatientProvider>
    </Provider>
  );
}

export default App;
