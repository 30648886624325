import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  patients: <any>false || [],
};

export const PatientListSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setpatients: (state, action: PayloadAction<any>) => {
      state.patients = action.payload;
    },
    addPatients: (state, action: PayloadAction<any[]>) => {
      state.patients = [...state.patients, ...action.payload];
    },
    clearPatients: (state) => {
      state.patients = false;
    },
  },
});

export const { setpatients, clearPatients, addPatients } = PatientListSlice.actions;

export const selectPatientsList = (state: any) => state.patients;

export default PatientListSlice.reducer;
