import styles from "../styles.module.css";
import $ from "jquery";
import {
  ArrowPathIcon,
  ChevronRightIcon,
  ArrowRightOnRectangleIcon,
  UserIcon, DevicePhoneMobileIcon
} from "@heroicons/react/24/outline";
import { Text } from "@cdt-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../store/slices/userSlice";

import { useEffect } from "react";

interface Card {
  signOut?: () => void;
  type: string;
  setIsActive: (value: boolean) => void;
  isCefalometria: boolean;
}

export function Card({ type, signOut, setIsActive, isCefalometria }: Card) {
  const idClient = localStorage.getItem('idClientLogin');
  const { user } = useSelector(selectUser);
  const roles = user[0]?.roles;
  const hash = user[0]?.hash;
  const navigate = useNavigate();
  const handleInstall = () => {

    let deferredPrompt;

    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      deferredPrompt = event;
      document.getElementById('installButton').style.display = 'block';
    });

    document.getElementById('installButton').addEventListener('click', () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('Usuário aceitou instalar o PWA');
          } else {
            console.log('Usuário recusou instalar o PWA');
          }
          deferredPrompt = null;
        });
      }
    });
  };

  const handleNavigation = (type) => {
    setIsActive(false);

    switch (type) {
      case 'VC':
        navigate('/dashboard/clinica');
        break;
      case 'sair':
        if (idClient !== 'null') {
          navigate(isCefalometria ? `/?cli=${idClient}&cefalometria=s` : `/?cli=${idClient}`);
        } else {
          navigate('/');
        }
        signOut();
        break;
      case 'dados':
        if (roles === 'NAOAUTENTICADO') {
          navigate('/Cadastro');
        } else if (roles === 'AUTENTICADO') {
          navigate('/dashboard/editar-perfil');
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let deferredPrompt;
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;
      $("#installModal").show();
    });

    $("#installButton").on("click", () => {
      if (deferredPrompt) {
        console.log("O usuário aceitou a instalação");
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("O usuário aceitou a instalação");
          } else {
            console.log("O usuário recusou a instalação");
          }
          deferredPrompt = null;
          $("#installModal").hide();
        });
      }
    });

  }, []);

  return (
    <div className={styles.cardContainer} onClick={() => handleNavigation(type)}>
      <div className={styles.cardLeftContainer}>
        <div className={styles.iconContainer}>
          {type === "VC" ? (
            <ArrowPathIcon width={16} />
          ) : type === "sair" ? (
            <ArrowRightOnRectangleIcon width={16} color="red" />
          ) : type === "dados" ? (
            <UserIcon width={16} />
          ) : type === "app" ? (
            <DevicePhoneMobileIcon width={16} />
          ) : (
            <></>
          )}
        </div>
        <div className={styles.containerLinks}>
          {type === "VC" ? (
            <div>
              <Text className={styles.cardMainText}>
                Vincular Clínicas
              </Text>

              <Text className={styles.cardSubText}>
                Adicione outras clínicas em sua conta
              </Text>
            </div>
          ) : type === "sair" ? (
            <div>
              <Text className={styles.cardMainText}>
                Sair
              </Text>

              <Text className={styles.cardSubText}>
                Clique aqui para fazer logout
              </Text>
            </div>
          ) : type === "dados" && roles === "NAOAUTENTICADO" ? (
            <div>
              <Text className={styles.cardMainText}>
                Dados Pessoais
              </Text>

              <Text className={styles.cardSubText}>
                Cadastre ou edite seus dados
              </Text>
            </div>
          ) : type === "dados" && roles === "AUTENTICADO" ? (
            <div>
              <Text className={styles.cardMainText}>
                Dados Pessoais
              </Text>

              <Text className={styles.cardSubText}>
                Cadastre ou edite seus dados
              </Text>
            </div>
          )
            : (
              <></>
            )}
        </div>
      </div>
      <div>
        <ChevronRightIcon width={16} color="#1976D2" />
      </div>
    </div>
  );
}
