import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
/* import { Button, Checkbox } from '@cdt-ui/react' */
import { Button } from "@mui/material";
import {
  ArrowDownTrayIcon
} from "@heroicons/react/24/solid";
import { saveAs } from "file-saver";

import { ModalShareProfile } from "../../../../components/ModalShareProfile";
import { ModalSheetChoice } from "./ModalSheetChoice/ModalSheetChoice";
import { ModalSheetShareProfile } from "./ModalSheetShareProfile/ModalSheetShareProfile";
import { ImageComponent } from "./ImageComponent";
import { useMediaQuery } from "react-responsive";
import JSZip from "jszip";

import styles from "./styles.module.css";

export function Imagens({
  imagensPaciente,
  PatientNameAndId,
  dataAtendimento,
  imagemPerfil,
  loadingImagens,
  isShare,
}: any) {
  const location = useLocation();

  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 684px)" });
  const [isModalSheetChoiceOpen, setModalSheetChoiceOpen] = useState(false);
  const [isModalSheetShareProfileOpen, setModalSheetShareProfileOpen] =
    useState(false);

  const showAddButton = selectedImages?.length > 0;

  const handleSelectAllImages = () => {
    if (selectAll) {
      setSelectedImages([]);
    } else {
      const allImages = imagensPaciente?.map((image: any) => ({
        link: image.link,
        nome: image.nome,
        complemento: image.complemento,
      }));
      setSelectedImages(allImages);
    }
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  const handleImageClick = (imageLink: string, imageName: string) => {
    setSelectedImages((prevSelected: any) => {
      if (
        prevSelected.some(
          (selectedImage: any) => selectedImage.link === imageLink
        )
      ) {
        return prevSelected?.filter(
          (selectedImage: any) => selectedImage.link !== imageLink
        );
      } else {
        return [...prevSelected, { link: imageLink, nome: imageName }];
      }
    });
  };

  const handleOPenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalSheetChoice = () => {
    if (isMobileScreen && selectedImages.length >= 1) {
      setModalSheetChoiceOpen(true);
    }
  };

  const handleCloseModalSheetChoice = () => {
    setModalSheetChoiceOpen(false);
  };

  const handleOpenModalSheetShareProfile = () => {
    setModalSheetShareProfileOpen(true);
  };

  const handleCloseModalSheetShareProfile = () => {
    setModalSheetShareProfileOpen(false);
  };

  const zip = new JSZip();
  const promises = [];

  function getExtensionFromLink(link) {
    const parts = link.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1];
    }
    return null;
  }
  const [loadingNextPage, setLoadingNextPage] = useState(false);

  const handleDownloadClick = () => {
    if (selectedImages?.length === 0) {
      alert("Nenhuma imagem selecionada.");
      return;
    }

    let requestsCount = 0;

    selectedImages.forEach((image: any, index) => {
      const { link, nome, complemento } = image;

      // Adicione uma verificação para garantir que `complemento` não seja `undefined`
      if (complemento) {
        let partes = complemento.split(".");
        let extensao = partes[partes.length - 1];

        if (!complemento.includes(".rar") && !complemento.endsWith(".zip") && !complemento.endsWith(".pdf")) {
          setLoadingNextPage(true);
          requestsCount++;
          var novoUrl1 = link;
          const promise = fetch(novoUrl1)
            .then((response) => response.blob())
            .then((blob) => {

              if (novoUrl1.includes(".jpg")) {
                const imageName = `${index + 1}_${nome}.jpg`;
                zip.file(imageName, blob);
              } else if (novoUrl1.includes(".dfx")) {
                const imageName = `${index + 1}_${nome}.dfx`;
                zip.file(imageName, blob);
              } else {
                const imageName = `${index + 1}_${nome}.stl`;
                zip.file(imageName, blob);
              }
            })
            .catch((error) => console.error("Erro ao baixar imagem:", error))
            .finally(() => {
              requestsCount--;
              if (requestsCount === 0) {
                setLoadingNextPage(false);
              }
            });

          promises.push(promise);
        }
      } else {
        setLoadingNextPage(true);
        requestsCount++;
        var novoUrl1 = link.replace("StlView", "getfile.aspx");
        const promise = fetch(novoUrl1)
          .then((response) => response.blob())
          .then((blob) => {
            if (link.includes(".jpg")) {
              const imageName = `${index + 1}_${nome}.jpg`;
              zip.file(imageName, blob);
            } else {
              const imageName = `${index + 1}_${nome}.stl`;
              zip.file(imageName, blob);
            }
          })
          .catch((error) => console.error("Erro ao baixar imagem:", error))
          .finally(() => {
            requestsCount--;
            if (requestsCount === 0) {
              setLoadingNextPage(false);
            }
          });

        promises.push(promise);

      }
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "imagens.zip");
      });
    });
  };

  useEffect(() => {
    if (location.pathname.includes("/dashboard/perfil-paciente/")) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className={styles.wrapper}>
      {imagensPaciente?.length == 0 ? (
        <div
          style={{
            textAlign: "center",
            paddingTop: "40px",
            color: "#afafaf",
            fontWeight: 500
          }}
        >
          <p>Sem imagens no momento.</p>
        </div>
      ) : (
        <>
          <div className={styles.containerTop}>
            <div className={styles.container_inputSelectAll}>
              <input
                className={styles.inputSelectAll}
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllImages}
              />
              <span>Selecionar todas</span>
            </div>

            <div className={styles.containerButtons}>
              {showAddButton && (
                <div className={`${styles.containerButtons_select_show}`}>

                  <div className={styles.containerButton_download}>
                    <Button
                      onClick={handleDownloadClick}
                      className={`${styles.buttonDownload} 
                                                ${isMobileScreen
                          ? styles.buttonMobile
                          : ""
                        }`}
                    >
                      <ArrowDownTrayIcon width={20} height={20} />{" "}
                      {!isMobileScreen &&
                        (loadingNextPage ? "Carregando" : "Baixar")}
                      { }
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.wrapperImg}>
            <div className={styles.wrapper_imagens}>
              {imagensPaciente?.map((image: any, imageIndex: number) =>
                !image.link.endsWith(".pdf") &&
                  !image.complemento.endsWith(".rar") &&
                  (!isMobileScreen || (!image.complemento.endsWith(".zip") && !image.complemento.endsWith(".dfx"))) ? (
                  <ImageComponent
                    key={imageIndex}
                    image={image}
                    selected={selectedImages.some(
                      (selectedImage) => selectedImage.link === image.link
                    )}
                    handleImageClick={handleImageClick}
                    imageTitle={image.nome}
                    imageDate={dataAtendimento}
                    handleOpenModalSheetChoice={handleOpenModalSheetChoice}
                    imagensPaciente={imagensPaciente}
                    isShare={isShare}
                  />
                ) : null
              )}
            </div>
          </div>
        </>
      )}

      {isModalOpen && (
        <ModalShareProfile
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          selectedImages={selectedImages}
          PatientNameAndId={PatientNameAndId}
        />
      )}
      {isModalSheetChoiceOpen && (
        <ModalSheetChoice
          isOpen={isModalSheetChoiceOpen}
          onRequestClose={handleCloseModalSheetChoice}
          handleOpenModalSheetShareProfile={handleOpenModalSheetShareProfile}
          image={selectedImages}
        />
      )}
      {isModalSheetShareProfileOpen && (
        <ModalSheetShareProfile
          isOpen={isModalSheetShareProfileOpen}
          onRequestClose={handleCloseModalSheetShareProfile}
          selectedImages={selectedImages}
          PatientImageSource={imagemPerfil}
          PatientNameAndId={PatientNameAndId}
        />
      )}
    </div>
  );
}
