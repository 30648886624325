import { useDispatch } from 'react-redux';

import { clearPatients, setpatients } from '../store/slices/patientListSlice';
import { setindicator } from '../store/slices/indicatorListSlice';
import { setClinic } from '../store/slices/clinicSlice';
import { setResearchPatient } from '../store/slices/researchPatientSlice';
import { resetIsClinicConnectionsComplete, setIsClinicConnectionsComplete } from '../store/slices/isClinicConnectionsCompleteSlice';
import { clearTempPatients, setTempPatients } from '../store/slices/tempPatientListSlice';

export function useReloadPatientsList() {
    const dispatch = useDispatch();

    return function handleReloadList() {
        dispatch(setpatients([]));
        dispatch(setindicator(false));
       // dispatch(setClinic(false));
        dispatch(setResearchPatient(false));
        dispatch(setIsClinicConnectionsComplete(false));
        dispatch(setTempPatients([]));
        dispatch(clearPatients());
        dispatch(clearTempPatients());
        dispatch(resetIsClinicConnectionsComplete());

        localStorage.setItem("currentClinicConnection", null);
        localStorage.setItem("fatestClinicIndex", null);
        localStorage.setItem("currentPageNumber", null);
        localStorage.setItem("patientFilters", null);
        localStorage.setItem("scrollValue", "0");
        localStorage.setItem("totalPages", "0");
        localStorage.setItem("page", "0");
        localStorage.setItem("atd", "null");
        localStorage.setItem("toConclude", "0");
    };
}
