import { Cog6ToothIcon } from '@heroicons/react/24/outline'

import styles from './styles.module.css'
import { useEffect, useState } from 'react';
import { CubeTransparent, File } from '@phosphor-icons/react';
import { Imagens2D } from '../Pages/Imagens2D/Imagens2D';
import { View3D } from '../Pages/3D/3D';
import { OpcoesView } from '../Pages/OpcoesView/OpcoesView';
import { Dicom } from '../Pages/Dicom';
import { is3dTypeUtils } from '../../../../utils/fileTypes/is3dTypeUtils';
import { isDicomTypeUtils } from '../../../../utils/fileTypes/isDicomTypeUtils';

interface NavigationSidebarViewProps {
    imageParams?: string;
    ocefx: any;
    oViewer: any;
    textPont?: string;
    pacienteAllImagensArray: any[];
    pacientAllImagens3d: any[];
    theSelectImage?: string;
    selectedImageChange?: any;
    isMobile?: boolean;
    handleChanceTheSelectedImage: (urlImage: string) => void;
    getImagenRotation: (rotate: number) => void;
    onViwerActivesChange: (newViwerActives: any) => void;
    handleCollapse?: () => void;
}

export function NavigationSidebarView({
    imageParams,
    ocefx,
    oViewer,
    textPont,
    theSelectImage,
    pacienteAllImagensArray,
    pacientAllImagens3d,
    selectedImageChange,
    isMobile,
    handleChanceTheSelectedImage,
    getImagenRotation,
    onViwerActivesChange,
    handleCollapse
}: NavigationSidebarViewProps) {

    const getInitialActiveTab = () => {
        if (isDicomTypeUtils(imageParams)) {
            return 'dicom';
        } else if (is3dTypeUtils(imageParams)) {
            return '3d';
        } else {
            return 'imagens';
        }
    }

    const [activeTab, setActiveTab] = useState(getInitialActiveTab());

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    const renderContent = () => {
        switch (activeTab) {
            case "imagens":
                return isMobile ? null : (
                    <Imagens2D
                        imageParams={imageParams}
                        ocefx={ocefx}
                        oViewer={oViewer}
                        pacienteAllImagensArray={pacienteAllImagensArray}
                        theSelectImage={theSelectImage}
                        handleChanceTheSelectedImage={handleChanceTheSelectedImage}
                        selectedImageChange={selectedImageChange}
                    />
                );
            case "dicom":
                return (
                    <Dicom
                        onViwerActivesChange={onViwerActivesChange}
                    />
                );
            case "3d":
                return (
                    <View3D
                        oViewer={oViewer}
                        theSelectImage={theSelectImage}
                        imageParams={imageParams}
                        pacientAllImagens3d={pacientAllImagens3d}
                        handleChanceTheSelectedImage={handleChanceTheSelectedImage}
                        isMobile={isMobile}
                        handleCollapse={handleCollapse}
                    />
                );
            case "opcoes":
                return (
                    <div>
                        <OpcoesView
                            imageParams={imageParams}
                            getImagenRotation={getImagenRotation}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const htmlBody = document.querySelector('html, body') as HTMLElement | null;

        if (htmlBody) {
            if (activeTab) {
                htmlBody.style.overflow = 'hidden';
            } else {
                htmlBody.style.overflow = 'visible';
            }
        }

        return () => {
            if (htmlBody) {
                htmlBody.style.overflow = 'visible';
            }
        };
    }, [activeTab]);

    return (
        <div className={styles.wrapper}>
            <nav>
                <ul>
                    {imageParams.endsWith(".dfx") || imageParams.endsWith(".zip") ? (
                        <li
                            title="Visualizar DICOM"
                            onClick={() => handleTabClick("dicom")}
                            className={activeTab === "dicom" ? `${styles.linkActive}` : ""}
                        >
                            <File size={14} />
                            {activeTab === "dicom" && <span>Dicom</span>}
                        </li>
                    ) : (
                        !isMobile && (
                            <li
                                title="Imagens"
                                onClick={() => handleTabClick("imagens")}
                                className={activeTab === "imagens" ? `${styles.linkActive}` : ""}
                            >
                                <File size={14} />
                                {activeTab === "imagens" && <span>Imagens</span>}
                            </li>
                        )
                    )}

                    {!imageParams.endsWith(".dfx") && !imageParams.endsWith(".zip") && (
                        <li
                            title="3D"
                            onClick={() => handleTabClick("3d")}
                            className={activeTab === "3d" ? `${styles.linkActive}` : ""}
                        >
                            <CubeTransparent size={14} />
                            {activeTab === "3d" && <span>3D</span>}
                        </li>
                    )}

                    <li
                        title="Opções"
                        onClick={() => handleTabClick("opcoes")}
                        className={activeTab === "opcoes" ? `${styles.linkActive}` : ""}
                    >
                        <Cog6ToothIcon width={14} />
                        {activeTab === "opcoes" && <span>Opções</span>}
                    </li>
                </ul>
            </nav>

            <div
                className={styles.wrapper_renderContent}
                style={{
                    borderTopLeftRadius: (activeTab === "imagens" || activeTab === "dicom") ? '0' : undefined,
                    borderTopRightRadius: activeTab === "opcoes" && '0',
                }}
            >
                <div
                    className={styles.renderContent}
                    style={{
                        borderTopLeftRadius: (activeTab === "imagens" || activeTab === "dicom") ? '0' : undefined,
                        borderTopRightRadius: activeTab === "opcoes" && '0',
                    }}
                >
                    {renderContent()}
                </div>
            </div>
        </div>
    )
}