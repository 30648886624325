import styles from "./styles.module.css";
import {
  PrinterIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/solid";

const FloppyDiskIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.875 7.13282V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H12.8672C12.9483 3.12472 13.0288 3.14044 13.1038 3.17128C13.1789 3.20211 13.2472 3.24745 13.3047 3.30469L16.6953 6.69532C16.7526 6.75284 16.7979 6.8211 16.8287 6.89617C16.8596 6.97124 16.8753 7.05166 16.875 7.13282V7.13282Z"
        stroke="#007E69"
        stroke-width="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.25 16.875V11.875C6.25 11.7092 6.31585 11.5503 6.43306 11.4331C6.55027 11.3158 6.70924 11.25 6.875 11.25H13.125C13.2908 11.25 13.4497 11.3158 13.5669 11.4331C13.6842 11.5503 13.75 11.7092 13.75 11.875V16.875"
        stroke="#007E69"
        stroke-width="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.875 5.625H7.5"
        stroke="#007E69"
        stroke-width="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

interface ButtonsContainerProps {
  selectedPatient: any;
  handleOpenModalSendRequest: () => void;
  handleDownload: () => void;
  handleOpenModalSaveModel: () => void;
  modelData: any;
  examRequestData: any;
  selectedClinic: any;
  footerRealigment: (
    desiredEditorHeight: number,
    footerMargin?: number | string
  ) => void;
  setModalErrorOrSucessMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setModalType: React.Dispatch<React.SetStateAction<string>>;
  isAboveLimit: boolean;
}

export const ButtonsContainer = ({
  selectedPatient,
  handleOpenModalSendRequest,
  handleDownload,
  handleOpenModalSaveModel,
  modelData,
  examRequestData,
  selectedClinic,
  footerRealigment,
  setModalErrorOrSucessMessage,
  setModalType,
  isAboveLimit,
}: ButtonsContainerProps) => {
  return (
    <div className={styles.buttonsContainer}>
      <div
        className={styles.containerButtonPrint}
        onClick={() => {
          if (isAboveLimit) {
            setModalErrorOrSucessMessage(true);
            setModalType("errorAboveLimit");
          } else {
            handleDownload();
          }
        }}
      >
        <button>
          <PrinterIcon width={16} />
          Imprimir
        </button>
      </div>

      <div className={styles.containerLastButtons}>
        <div
          className={styles.containerButtonSave}
          onClick={() => {
            if(isAboveLimit) {
              setModalErrorOrSucessMessage(true);
              setModalType("errorAboveLimit");
            } else {
              handleOpenModalSaveModel();
            }
          }}
        >
          <button>
            <FloppyDiskIcon />
            {modelData ? "Atualizar modelo" : "Salvar como modelo"}
          </button>
        </div>

        <div
          className={styles.containerButtonSend}
          onClick={() => {
            if (isAboveLimit) {
              setModalErrorOrSucessMessage(true);
              setModalType("errorAboveLimit");
            } else {
              footerRealigment(1158, "16px");
              handleOpenModalSendRequest();
            }
          }}
        >
          {selectedClinic && selectedPatient && (
            <button>
              <ArrowTopRightOnSquareIcon width={16} />
              {examRequestData ? "Atualizar Requisição" : "Enviar"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
