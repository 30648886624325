import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  patientsCefxConcluidos: <any>[],
};

export const PatientCefxConcluidosSlice = createSlice({
  name: "patientsCefxConcluidos",
  initialState,
  reducers: {
    setpatientCefxConcluidos: (state, action: PayloadAction<any>) => {
      state.patientsCefxConcluidos = action.payload;
    },
    addPatientCefxConcluidos: (state, action: PayloadAction<any[]>) => {
      state.patientsCefxConcluidos = [...action.payload];
      //state.patientsCefxConcluidos = [...state.patientsCefxConcluidos, ...action.payload];
    },
    clearPatientCefxConcluidos: (state) => {
      state.patientsCefxConcluidos = [];
    },
  },
});

export const { setpatientCefxConcluidos, addPatientCefxConcluidos, clearPatientCefxConcluidos } = PatientCefxConcluidosSlice.actions;

export const selectPatientCefxConcluidos = (state: any) => state.patientsCefxConcluidos;

export default PatientCefxConcluidosSlice.reducer;
