import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { TableBody } from "./TableBody";

import styles from "./styles.module.css";
import { useState } from "react";
import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";

interface PatientProps {
  Id: number;
  SeqAtend: number;
  Paciente: string;
  Clinica?: string;
  Status: string;
  DataAtend: string;
  DataNasc: string;
  Idade: string;
  Indicacao: string;
  IdPaciente: number;
  Sexo: string;
  Telefone: string | null;
  Imagem: string;
  TodosExames: any[] | null;
  ImagensPaciente: any[] | null;
  Exames: any[] | null;
  Cefalometrias: any[] | null;
  ControleProcessos: any[] | null;
  VideosPaciente: any[] | null;
  TipoUsuario: string | null;
  SS: string;
}

interface TableProps {
  list: any;
  amount?: number;
  data: any;
  getScrollTo?: any;
  setLastArrowChanged?: React.Dispatch<any>;
  noPatientsCefx?: boolean;
  loadingButtonChecklist?: boolean;
  noResultSearch?: boolean;
}

export function Table({
  getScrollTo,
  setLastArrowChanged,
  list,
  amount,
  noPatientsCefx,
  loadingButtonChecklist,
  noResultSearch,
  data: { patientList },
}: TableProps) {
  const [arrowState, setArrowState] = useState({
    paciente: "up",
    dataAtend: "down",
    dataNasc: "up",
    indicacao: "up",
    clinic: "up",
    status: "up",
  });

  const { isCefalometria } = useSelector(selectIsCefalometria);

  const arrowSize = 12;

  const handleReorder = (type, prop) => {
    if (prop === "dataNasc" && type === "up") {
      setArrowState({ ...arrowState, [prop]: "down" });
    } else if (prop === "dataNasc" && type === "down") {
      setArrowState({ ...arrowState, [prop]: "up" });
    }
    setLastArrowChanged({ prop, type });
    setArrowState({ ...arrowState, [prop]: type });
  };
  const OpenWhatsapp = () => {
    // var numeros = infoPacient.telefone.replace(/\D/g, "");
    // var numeroDesejado = numeros.substring(0, 10);
    // window.location.href = "https://wa.me/55" + numeroDesejado;
  };

  return (
    <section className={styles.container}>
      {/* <div className={styles.container_amountOfCustomers}>
        <p>{amount} encontrados</p>
      </div> */}

      <div className={styles.container_table}>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Paciente
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.paciente === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "paciente")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "paciente")}
                      />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div>
                  Data
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.dataAtend === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "dataAtend")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "dataAtend")}
                      />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div>
                  Idade
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.dataNasc === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "dataNasc")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "dataNasc")}
                      />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div>
                  Indicação
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.indicacao === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "indicacao")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "indicacao")}
                      />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div>
                  Clínica
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.clinic === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "clinic")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "clinic")}
                      />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div>
                  Previsão
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.dataAtend === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "dataAtend")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "dataAtend")}
                      />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div>
                  Situação
                  <span className={styles.upDownIconsContainer}>
                    {arrowState.status === "up" ? (
                      <ChevronUpIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("down", "status")}
                      />
                    ) : (
                      <ChevronDownIcon
                        width={arrowSize}
                        className={styles.upDownIcons}
                        onClick={() => handleReorder("up", "status")}
                      />
                    )}
                  </span>
                </div>
              </th>
              <th>Compartilhar</th>
            </tr>
          </thead>
          {noResultSearch ? (
            <p
              style={{
                textAlign: "center",
                marginTop: "2rem",
                opacity: ".9",
                color: "#c80000",
              }}
            >
              Paciente não foi encontrado.
            </p>
          ) : (
            list?.data.map((patient: PatientProps, index: number) => {
              return (
                <TableBody
                  key={index}
                  bodyContents={!patient ? [] : patient}
                  getScrollTo={getScrollTo}
                />
              );
            })
          )}
        </table>
      </div>
    </section>
  );
}
