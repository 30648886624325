import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  patientsCefxEmAndamento: <any>[],
};

export const PatientCefxEmAndamento = createSlice({
  name: "patientsCefxEmAndamento",
  initialState,
  reducers: {
    setpatientCefxEmAndamento: (state, action: PayloadAction<any>) => {
      state.patientsCefxEmAndamento = action.payload;
    },
    addPatientCefxEmAndamento: (state, action: PayloadAction<any[]>) => {
      state.patientsCefxEmAndamento = [...action.payload];
     // state.patientsCefxEmAndamento = [...state.patientsCefxEmAndamento, ...action.payload];
    },
    clearPatientCefxEmAndamento: (state) => {
      state.patientsCefxEmAndamento = [];
    },
  },
});

export const { setpatientCefxEmAndamento, addPatientCefxEmAndamento, clearPatientCefxEmAndamento } = PatientCefxEmAndamento.actions;

export const selectPatientsCefxEmAndamento = (state: any) => state.patientsCefxEmAndamento;

export default PatientCefxEmAndamento.reducer;
