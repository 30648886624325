import Modal from "react-modal";
import { Formik, Form, FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "@cdt-ui/react";
import * as yup from "yup";

import CheckIcon from "../../assets/iconCheck.png";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { TextInput } from "../Form/TextInput";

import styles from "./styles.module.css";
import { Input } from "../Form/Input";
import Api from "../../helpers/Api";
import { ErrorInfo } from "react-dom/client";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useState } from "react";

interface ModalRecoverPasswordProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleOpenModalErrorOrSuccess: () => void;
  setModalType: React.Dispatch<React.SetStateAction<string>>;
  setCustomDescription?: React.Dispatch<React.SetStateAction<string>>;
}

export function ModalRecoverPassword({
  isOpen,
  onRequestClose,
  handleOpenModalErrorOrSuccess,
  setModalType,
  setCustomDescription
}: ModalRecoverPasswordProps) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmitRecoverPassword = async (values: FormikValues) => {
    setLoading(true);

    const { email } = values;
    const logon = email;

    await Api.RecoverPassword(logon)
      .then((result) => {
        if (result.isSuccess === true) {
/*           toast("Link enviado com sucesso!", {
            position: "top-right",
            style: {
              background: "#008a00",
              color: "#fff",
            },
          }); */
          onRequestClose();
          setModalType('sentEmail');
          handleOpenModalErrorOrSuccess();
          setLoading(false);
        } else if (result.isSuccess === false) {
          onRequestClose();
          setModalType('errorEmail');
          handleOpenModalErrorOrSuccess();
        }
        setLoading(false);
      })
      .catch((error: ErrorInfo) => {
/*         if (axios.isAxiosError(error)) {
          toast("Ocorreu um erro!", {
            position: "top-right",
            style: {
              background: "#c80000",
              color: "#fff",
            },
          });
        } */
        onRequestClose();
        setModalType('errorEmail');
        if(setCustomDescription) {
          setCustomDescription('Ocorreu um erro!');
        }
        handleOpenModalErrorOrSuccess();
        console.log(error);
        setLoading(false);
      });
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("E-mail inválido, verifique e tente novamente")
      .max(45, ({ max }) => `máximo ${max} caracteres`)
      .required("Campo obrigatório"),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react_modal_overlay"
      className="react-modal-VerifyEmail"
    >
      <header className={styles.header}>
        <div className={styles.header_container_icon}>
          <img src={CheckIcon} alt="Icone de check na cor verde" />
        </div>

        <XMarkIcon title="Fechar" onClick={onRequestClose} />
      </header>

      <div className={styles.container_content}>
        <h2>Recuperar senha?</h2>
        <p>Você receberá um link para a recuperação de senha </p>
      </div>

      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values, { resetForm }) => {
          handleSubmitRecoverPassword(values);
          resetForm();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, values, setFieldValue }: any) => (
          <Form>
            <div className={styles.container_input}>
              <Input name="email" title="E-mail" error={errors.email} />
            </div>

            <div className={styles.container_button}>
              <Button
                type="submit"
                variant="greenDark"
                size="full"
                disabled={loading && true}
              >
                {loading ? (
                  <div className={styles.container_buttons_loading}>
                    <div className={styles.loading_spinne}></div>
                  </div>
                ) : (
                  "Enviar"
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
