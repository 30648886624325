import { Heading } from "@cdt-ui/react";
import styles from "./styles.module.css";

export function BoxVideoInstitutionalResponsive() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container_video}>
        <iframe
          id="panda-87ecb9fe-f17d-41d7-942a-877e56c709e6"
          height="1"
          className={styles.video}
          src="https://player-vz-fb154304-6eb.tv.pandavideo.com.br/embed/?v=87ecb9fe-f17d-41d7-942a-877e56c709e6"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
